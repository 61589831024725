<template>
  <button class="pxp-button" :class="['size-' + size, 'mode-' + mode]">
    <template v-if="loading">
      <ButtonLoader />
    </template>

    <template v-else>
      <p class="pxp-button__content" :class="['position-icon-' + iconPosition]">
        <IconNew
          v-if="iconName"
          :name="iconName"
          :class="['size-' + size, 'position-' + iconPosition]"
          class="pxp-button__icon"
        />
        {{ text }}
      </p>
    </template>
  </button>
</template>

<script>
import ButtonLoader from "@/components/UI-Kit/loaders/ButtonLoader";
import IconNew from "@/components/UI-Kit/IconNew";

const buttonSize = ["l", "m", "s"];
const buttonMode = ["default", "green", "red", "dark", "ghost", "gray"];
const iconPosition = ["left", "right"];

export default {
  name: "ButtonNew",
  components: { IconNew, ButtonLoader },
  props: {
    text: {
      type: String,
      required: true
    },

    iconName: {
      type: [String, undefined],
      required: false,
      default: undefined
    },

    iconPosition: {
      type: String,
      required: false,
      validator: position => {
        return iconPosition.indexOf(position) !== -1;
      }
    },

    size: {
      type: String,
      required: false,
      default: "l",
      validator: size => {
        return buttonSize.indexOf(size) !== -1;
      }
    },

    mode: {
      type: String,
      required: false,
      default: "default",
      validator: mode => {
        return buttonMode.indexOf(mode) !== -1;
      }
    },

    loading: {
      type: Boolean,
      required: false,
      default: false
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/styles/color-scheme.scss";
@import "~@/assets/styles/hover-active-change.scss";

.pxp-button {
  cursor: pointer;

  &,
  &:hover,
  &:active,
  &:focus {
    border: none;
    outline: none;
  }

  &.mode-green {
    @include changeBackgroundMode(
      $pxp-green-color,
      $pxp-green-color-lighten,
      $pxp-green-color-darken
    );
    color: $pxp-white-color;
  }
  &.mode-red {
    @include changeBackgroundMode(
      $pxp-red-color,
      $pxp-red-color-lighten,
      $pxp-red-color-darken
    );
    color: $pxp-white-color;
  }
  &.mode-dark {
    @include changeBackgroundMode(
      $pxp-blue-color,
      $pxp-blue-color-lighten,
      $pxp-blue-color-darken
    );
    color: $pxp-white-color;
  }
  &.mode-ghost {
    @include changeBackgroundMode(
      $pxp-gray-accent-color-dimmer,
      $pxp-gray-accent-color-dimmer,
      #e5e5e5
    );
    @include changeBorderMode(
      1px,
      solid,
      #bfc4d6,
      $pxp-gray-color-darken,
      $pxp-gray-color-darken
    );
    color: $pxp-blue-added-color;
    font-weight: bold;
  }
  &.mode-default {
    @include changeBackgroundMode(
      $pxp-white-color,
      $pxp-white-color,
      $pxp-gray-accent-color-dimmer
    );
    @include changeBorderMode(
      1px,
      solid,
      $pxp-gray-color-lighten,
      $pxp-gray-color-darken,
      $pxp-gray-color-lighten
    );
    color: $pxp-black-color;
  }
  &.mode-gray {
    @include changeBackgroundMode(
      #dbdfec,
      darken(#dbdfec, 5%),
      darken(#dbdfec, 10%)
    );
    color: $pxp-blue-added-color;
  }



  &.mode-text {
    background-color: rgba(0,0,0,0);

    p {
      color: $pxp-blue-added-color;
      font-family: Work Sans;
      font-style: normal;
      font-weight: 600!important;
      font-size: 12px;
      line-height: 14px;
      /* identical to box height */

    }
  }

  &.size-l {
    height: 40px;
  }
  &.size-m {
    height: 32px;
  }

  &.size-s {
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 12px!important;
      line-height: 15px;

    }
    border-radius: 4px;
    height: 24px;
    padding: 0 8px;
  }

  &.size-l,
  &.size-m {
    p {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
    }
    border-radius: 32px;
    padding: 0 16px;
  }

  font-family: "Work Sans";
}

.pxp-button__icon {
  grid-area: pxp-button__icon;

  &.position-left {
    margin-right: 6px;
  }
  &.position-right {
    margin-left: 6px;
  }

  &.size-l,
  &.size-m {
    width: 13px;
    height: 13px;
  }

  &.size-s {
    width: 12px;
    height: 12px;
  }
}

.pxp-button__content {
  width: auto;
  margin: 0 auto;
  display: grid;
  grid-template-columns: auto auto;
  &.position-icon-left {
    grid-template-areas: "pxp-button__icon .";
  }
  &.position-icon-right {
    grid-template-areas: ". pxp-button__icon";
  }

  place-items: center;
  place-content: center;
}
</style>
