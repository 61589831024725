<template>
  <button class="uk-button pxp__button" :class="[classButton]">
    <template v-if="loading">
      <ButtonLoader />
    </template>
    <template v-else-if="ok">
      <img src="~@/assets/images/button-ok.svg" alt="ok" />
    </template>
    <template v-else>
      {{ text }}
    </template>
  </button>
</template>

<script>
import { computed } from "vue";
import ButtonLoader from "@/components/UI-Kit/loaders/ButtonLoader";

export default {
  name: "Button",
  components: { ButtonLoader },
  props: {
    text: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    ok: {
      type: Boolean,
      required: false,
      default: false
    },
    styleModifiers: {
      type: String,
      required: false,
      default: "default",
      validator: mode => {
        return (
          [
            "default",
            "success",
            "danger",
            "primary",
            "secondary",
            "secondary-dark",
            "text",
            "link"
          ].indexOf(mode) !== -1
        );
      }
    }
  },
  setup(props) {
    const classButton = computed(() => "uk-button-" + props.styleModifiers);

    return {
      classButton
    };
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/styles/variables.scss";

.pxp__button {
  border-radius: 32px;
  width: 100%;
  cursor: pointer;
}

.uk-button-success {
  background-color: $global-success-background;
  color: white;
  &:hover {
    background-color: darken($global-success-background, 5%);
  }
  &:active {
    background-color: darken($global-success-background, 10%);
  }
}

.uk-button-secondary-dark {
  background-color: $global-secondary-dark-background;
  color: white;
  &:hover {
    background-color: lighten($global-secondary-dark-background, 5%);
  }
  &:active {
    background-color: lighten($global-secondary-dark-background, 10%);
  }
}

$text-button-color: #4973ab;

.uk-button-text {
  color: $text-button-color;
  background: none;
  &:hover {
    color: darken($text-button-color, 10%);
  }
  &:active {
    color: darken($text-button-color, 15%);
  }
}
</style>
