<template>
  <Card size="small" class="canvas-card" @focusout="updatedCard">
    <template v-slot:header>
      <div class="canvas-card__header">
        <h3 class="canvas-card__title">{{ canvasCardName.name }}</h3>
        <IconNew
          name="video-canvas-card"
          class="canvas-card__video"
          @click="showVideo = true"
        ></IconNew>
      </div>
    </template>

    <template v-slot:body>
      <template v-if="canvasCardData">
        <div class="canvas-card__content">
          <template v-if="canvasCardName.type === 'list'">
            <ListEditorBlock
              :id="'canvas-data-' + canvasCardName.id"
              v-model="dataReactive"
              v-model:new="newDataActivator"
              v-model:order="dataOrder"
              sortable-group="test-dss"
              mode="canvas-list"
            />
          </template>
          <template v-else>
            <TextEditorBlock :id="'canvas-data-' + canvasCardName.id" v-model:item="data"/>
          </template>
        </div>

        <!-- video -->
        <Modal
          :id="'canvas-video-' + canvasCardName.id"
          v-model:show="showVideo"
          is-center
          class="video-modal"
        >
          <video
            :src="canvasCardName.videoLink"
            width="1920"
            height="1080"
            frameborder="0"
            uk-video="autoplay: inview"
            uk-responsive
          ></video>
        </Modal>
        <Modal
          :id="'canvas-comments-' + canvasCardName.id"
          v-model:show="showComments"
          is-center
          class="comments-modal"
        >

          <CommentsCard :title="canvasCardName.name" content-type="canvas-data" :show="showComments" :id="canvasCardData.id" :workspace="workspaceId" @new-comment="countComments += 1" />
        </Modal>
      </template>
    </template>

    <template v-slot:footer>
      <div class="canvas-card__action">
        <IconNew
          name="plus-canvas-card"
          class="canvas-card__plus action"
          @click="addData"
        ></IconNew>
        <IconNew
          name="comment-canvas-card"
          class="canvas-card__comment action"
          @click="showComments = true"
        ></IconNew>
        <span class="canvas-card__number">{{
          countComments
        }}</span>
      </div>
    </template>
  </Card>
</template>

<script>
import Card from "@/components/UI-Kit/Card";
import Modal from "@/components/UI-Kit/Modal";
import { ref, isRef, computed, watch, reactive, toRefs, onUpdated } from "vue";
import CommentsCard from "@/components/Comments/CommentsCard";
import Button from "@/components/UI-Kit/Button";
import TextArea from "@/components/UI-Kit/TextArea";
import ListEditorBlock from "@/components/UI-Kit/ListEditor";
import IconNew from "@/components/UI-Kit/IconNew";
import TextEditorBlock from "@/components/UI-Kit/TextEditorBlock";


const setup = function(props, {emit}) {


  // for video
  const showVideo = ref(false);

  // for data
  const data = computed({
    get: () => props.canvasCardData.data,
    set: (value) => {
      props.canvasCardData.data = value
      emit("updated")
    }
  });

  const addData = function() {
    if (props.canvasCardName.type === "list") {
      newDataActivator.value = true;
    } else {
      document.querySelector(`#canvas-data-${props.canvasCardName.id}`).focus()
    }
  };
  const myUpdate = ref(true)


  // for data with type=list
  const newDataActivator = ref(false);

  const dataReactive = computed({
    get: () => reactive(data.value.split("\n")),
    set: (value) => {
      data.value = value.join("\n");
    }
  });

  const dataOrder = ref([...Array(dataReactive.length).keys()]);

  watch(dataOrder, newOrder => {
    dataReactive.value = newOrder.map(item => dataReactive.value[item]);
  });

  const updatedCard = function () {
    data.value = dataReactive.value.join("\n")
  }

  /*watch(() => props.canvasCardData.data, (newValue) => {
    myUpdate.value = false
    dataReactive.splice(0, dataReactive.length)
    myUpdate.value = false
    dataReactive.push(...(newValue.split("\n")))
    console.log('ura')
  })*/


  // for comment
  const showComments = ref(false);
  const countComments = ref(props.canvasCardData.comments ? props.canvasCardData.comments.length : 0)
  return {
    showVideo,
    showComments,
    data,
    dataReactive,
    newDataActivator,
    dataOrder,
    addData,
    countComments,
    updatedCard
  };
};

export default {
  name: "CanvasCard",
  components: {
    TextEditorBlock,
    ListEditorBlock,
    Card,
    Modal,
    CommentsCard,
    Button,
    IconNew,
    TextArea
  },
  props: {
    canvasCardName: {
      type: Object,
      required: true
    },
    canvasCardData: {
      type: Object
    },
    workspaceId: {
      type: String,
      required: true
    }
  },
  setup
};
</script>

<style scoped lang="scss">
.canvas-card {
  box-sizing: border-box;
  margin: 8px;
  box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  text-align: left;

  p {
    font-weight: normal;
    font-size: 14px;
    line-height: 125%;
    color: #000000;
  }
}

.canvas-card__header {
  display: flex;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;
  padding: 16px;

  .canvas-card__title {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    text-align: left;
    color: #13246e;
    width: 100%;
  }

  .canvas-card__video {
    justify-self: right;
    margin-left: 15px;
    width: 16px;
    height: 16px;
    cursor: pointer;
  }
}

.canvas-card__content {
  margin-top: -10px;
  padding: 16px;
  padding-top: 0px;


  .list-editor-block {
    text-align: left;
  }
}



.canvas-card__action {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0 16px;
  padding-bottom: 8px;
  align-items: center;
  .canvas-card__comment,
  .canvas-card__plus {
    width: 16px;
    height: 16px;
  }

  .canvas-card__plus {
    margin-right: 7px;
  }

  .canvas-card__comment {
    margin-right: 1px;


  }

  .canvas-card__number {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;

    color: #acb0be;
  }

  .action {
    cursor: pointer;
  }
}




.custom__text-area {
  box-shadow: 0 0 4px white;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;

  color: #000000;
}

// modal
.modal-comments__header {
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;

  color: #000000;
}

.button-send-comment {
  width: 180px;
  height: 40px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  /* White #FFF */
  color: #ffffff;
}

.comments-modal{
  overflow: hidden;
}


</style>