<template>
  <article v-bind:class="{ loaded: loadMore }" class="uk-comment uk-visible-toggle pxp-comment" tabindex="-1" ref="commentbox">
    <header class="uk-comment-header uk-position-relative">
      <div class="uk-grid-medium uk-flex-middle" uk-grid>
        <div class="uk-width-auto">
          <Avatar class="uk-comment-avatar" :src="avatar" :id="'comment-author-image-1'" :full-name="authorName" :size="50" alt=""/>
        </div>
        <div class="uk-width-expand">
          <h3 class="uk-comment-title uk-margin-remove comment-title"><a class="uk-link-reset" href="#">{{ authorName }}</a></h3>
          <p class="uk-comment-meta uk-margin-remove-top"><a class="uk-link-reset" href="#">{{ dateFormat }}</a></p>
        </div>
      </div>
    </header>
    <div class="uk-comment-body">
      <p>{{content}}</p>
    </div>
    <div v-if="displayed" class="load-more" v-on:click="loadMore=!loadMore">
      <h4 v-if="!loadMore">Load more</h4>
      <h4 v-else>Hide</h4>
      </div>
  </article>
</template>

<script>
import Avatar from "@/components/UI-Kit/Avatar";
import moment from "moment";
import {ref, computed, onMounted, watch, nextTick, onUpdated} from "vue";

export default {
  name: "Comment",
  components: {Avatar},
  setup (props) {
    const loadMore = ref(false);
    const displayed = ref(true);
    const commentbox = ref(null);

    const updateHeight = function () {
      if (props.content.length < 360) {
        displayed.value = false;
        loadMore.value = true
      }
    }

    onMounted(() => {
      updateHeight()
    })

    const dateFormat = computed(() => moment(props.date).format("DD/MM/yyyy"))

    return {
      dateFormat,
      loadMore, displayed, commentbox
    }
  },
  props: {
    id: {
      type: String,
      required: true
    },
    authorName: {
      type: String,
      required: true
    },
    avatar: {
      type: String,
      required: false,
      default: ""
    },
    date: {
      type: String,
      required: true
    },
    content: {
      type: String,
      required: true
    },
    show: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
.pxp-comment {
  text-align: left;
  margin-top: 15px;
  border-radius: 10px;
  width: calc(100% - 10px);
  min-width: calc(100% - 10px);
  height: 100%;
  max-height: 250px;
  box-shadow: 0 0 4px #cccccc;
  border: none;
  padding: 20px;
  background-color: white;
  overflow: hidden;
  position: relative;
  transition: 0.5s;
  box-sizing: border-box;
  margin-right: 5px;
  margin-left: 5px;
}

.pxp-comment:after{
  content: '';
  width: 100%;
  height: 110px;
  background: linear-gradient(rgba(255,255,255,0) 0%,  rgba(255,255,255,1) 60%, rgba(255,255,255,1) 100%);
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 0;
  transition: 0.5s
}

.loaded {
  max-height: 100%;
  transition: 0.5s
}

.loaded:after {
  display: none;
  transition: 0.5s
}

.load-more {
  z-index: 10;
  position: absolute;
  bottom: 10px;
  left: 50%;
  width: 90px;
  text-align: center;
  margin-left: -45px;
  cursor: pointer;
}

.uk-link-muted:hover {
  text-decoration: none;
}

.uk-position-top-right {
  margin: 0
}

.uk-flex-middle {
  align-items: auto;
}

.uk-comment-body {
  margin-bottom: 20px;
}

.comment-title {
  font-size: 16px;
}
</style>