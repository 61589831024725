<template>
  <div class="chosen-task">
    <h2 class="title">Task {{index + 1}}</h2>
    <p v-if="checkPostpone(index)" class="postpone">postponed</p>
    <template v-if="parsedSTask">
      <div class="task-wrapper">
        <Handler/>
        <div class="task">
          <p class="task-name">{{parsedSTask.name}}</p>
          <p class="rq-name">{{parsedSTask.rq}}</p>
        </div>
      </div>
      <p class="label">Today I plan...</p>
      <TextInputNew v-model="todoTask.plan"
                    size="s"
                    placeholder="Add notes here (optional)"
                    :id="'todo-today-plan-' + index"
                    @focusout="updateTTTaskByIndex(index)"
      />
      <p class="label">Estimated time</p>
      <PomodoroTime :max="8" v-model="todoTask.pomodoro" @change="updateTTTaskByIndex(index)"/>
      <div class="divider"/>
    </template>
  </div>
</template>

<script>
import TaskCard from "@/components/Research Question/TaskCard";
import TextInputNew from "@/components/UI-Kit/TextInputNew";
import {ref, computed, inject, nextTick, watch} from "vue";
import PomodoroTime from "@/components/UI-Kit/PomodoroTime";
import Handler from "@/components/UI-Kit/Handler";
import {updateTodoTask} from "@/api/todotoday";

export default {
  name: "ChosenTask",
  components: {Handler, PomodoroTime, TextInputNew, TaskCard},
  props: {
    todoTask: Object,
    index: Number
  },
  setup (props) {

    const rqs = inject("rqs")
    const tasks = inject("tasks")
    const updateTTTaskByIndex = inject("updateTTTaskByIndex")
    const checkPostpone = inject("checkPostponeByIndex")

    const parsedSTask = computed(() => {
      if (!props.todoTask.hasOwnProperty('type') && props.todoTask.hasOwnProperty('task') && tasks[props.todoTask.task]) {
        let task = tasks[props.todoTask.task]
        return {
          name: task.name,
          rq: typeof task.researchQuestion === "object"? task.researchQuestion.name: rqs[task.researchQuestion].name
        }
      } else {
        return undefined
      }
    })

    return {
      parsedSTask,
      checkPostpone,
      updateTTTaskByIndex
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/styles/color-scheme.scss";
.divider {
  width: 100%;
  height: 0;
  margin-top: 21px;
  border: 1px dashed #BFC4D6;
}

.chosen-task {
  .label {
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;
    margin: 8px 0 4px 0;
    color: #80869D;

    opacity: 0.8;
  }
  .title {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    margin: 16px 0 12px 0;
    color: #13246E;
  }

  .task-wrapper {
    margin: 12px 0;
  }
}


.task-wrapper {
  margin-bottom: 8px;
  background: $pxp-white-color-darken;
  border: 1px solid #E3E5EC;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  padding: 8px;
  .task {

    .rq-name {
      font-style: italic;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      /* identical to box height */

      margin-top: 8px;

      color: #000000;

      opacity: 0.5;
    }
    .task-name {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      /* identical to box height */


      color: #000000;
    }
  }
}

.postpone {
  font-weight: 500;
  font-size: 10px;
  font-style: italic;
  line-height: 12px;
  text-transform: uppercase;
  margin: -10px 0 4px 0;
  color: #80869D;

  opacity: 0.8;
}
</style>