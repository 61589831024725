import axios from "axios";

// Sprint

export async function getSprint(workspace) {
  try {
    const response = await axios.get('/sprints', {
      params: {
        workspace
      }
    })

    return response.data
  } catch {
    throw new Error();
  }
}

export async function createSprint(workspace, data) {
  try {
    const response = await axios.post('/sprints', {
      data,
      workspace
    })
    return response.data
  } catch {
    throw new Error();
  }
}

export async function completeSprint(workspace) {
  try {
    const response = await axios.put('/sprints/completed', {
      workspace
    })
    return response.data
  } catch {
    throw new Error();
  }
}


// Research question

export async function getResearchQuestion(workspace) {
  try {
    const response = await axios.get('/research-questions', {
      params: {
        workspace
      }
    })

    return response.data
  } catch {
    throw new Error();
  }
}

export async function createResearchQuestion(workspace, data) {
  try {
    const newData = {...data}
    const newMove = []
    if (newData.hasOwnProperty('sync')) {
      delete newData.sync
    }

    if (newData.hasOwnProperty('move')) {
      newMove.push(...data.move)
      delete newData.move
    }

    const response = await axios.post("/research-questions", {
      workspace,
      move: newMove,
      data: newData
    });

    return response.data;
  } catch {
    throw new Error();
  }
}

export async function updateResearchQuestion(id, workspace, data) {
  try {
    const newData = {...data}
    if (newData.hasOwnProperty('move'))
      delete newData.move

    if (newData.hasOwnProperty('sync'))
      delete newData.sync

    const response = await axios.put("/research-questions/" + id, {
      workspace,
      data: newData
    });

    return response.data;
  } catch {
    throw new Error();
  }
}

export async function deleteResearchQuestion(id, workspace) {
  try {
    const response = await axios.delete("/research-questions/" + id, {
      params: {
        workspace
      }
    });

    return response.data;
  } catch {
    throw new Error();
  }
}

export async function moveResearchQuestion(id, workspace, move) {
  // status can be: delete, done, rq

  try {
    const response = await axios.put("/research-questions/move/" + id, {
      workspace,
      move
    });

    return response.data;
  } catch {
    throw new Error();
  }
}

// Tasks

export async function getTask(workspace) {
  try {
    const response = await axios.get('/tasks', {
      params: {
        workspace
      }
    })

    return response.data
  } catch {
    throw new Error();
  }
}

export async function createTask(workspace, data) {
  try {

    const newData = {...data}
    const newMove = []
    if (newData.hasOwnProperty('sync')) {
      delete newData.sync
    }

    if (newData.hasOwnProperty('move')) {
      newMove.push(...data.move)
      delete newData.move
    }


    const response = await axios.post("/tasks", {
      workspace,
      move: newMove,
      data: newData
    });

    return response.data;
  } catch {
    throw new Error();
  }
}

export async function updateTask(id, workspace, data) {
  try {
    const newData = {...data}
    if (newData.hasOwnProperty('move'))
      delete newData.move

    if (newData.hasOwnProperty('sync'))
      delete newData.sync

    if (newData.hasOwnProperty('researchQuestion'))
      delete newData.researchQuestion

    const response = await axios.put("/tasks/" + id, {
      workspace,
      data: newData
    });

    return response.data;
  } catch {
    throw new Error();
  }
}

export async function moveTask(id, workspace, move) {
  // status can be: delete, done, doing, tоdо, rq
  try {
    const response = await axios.put("/tasks/move/" + id, {
      workspace,
      move
    });

    return response.data;
  } catch {
    throw new Error();
  }
}

export async function deleteTask(id, workspace) {
  try {
    const response = await axios.delete("/tasks/" + id, {
      params: {
        workspace
      }
    });

    return response.data;
  } catch {
    throw new Error();
  }
}
