<template>
  <div class="update-block">
    <p class="update-block__message">🎉 New version available!!!</p>
    <ButtonNew icon-position="left"
               class="update-block__button"
               mode="default"
               icon-name="smile"
               size="s" text="Update"
               @click="$emit('appUpdate')"/>
  </div>
</template>

<script>
import ButtonNew from "@/components/UI-Kit/ButtonNew";
export default {
  name: "UpdateMessage",
  components: {ButtonNew}
}
</script>

<style scoped lang="scss">
@import "~@/assets/styles/color-scheme";

.update-block {
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  background-color: $pxp-white-accent-color-brighter;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 4px 16px;
  margin: 0;
}

.update-block__message {
  font-weight: bold;
}

.update-block__button {
  width: 100px;
}
</style>