<template>
  <Card class="comments-card">
    <template v-slot:header>
      <h1 class="title">{{title}}</h1>
    </template>
    <template v-slot:body>
      <div class="comments-all" :id="'comments-all-' + id">
        <template v-if="statusLoadComments === 'load' && !show">
          <CircleLoader :ratio="3"/>
        </template>

        <template v-else-if="statusLoadComments === 'ok' && comments.length === 0">
          <div class="no-comments">
            <img src="@/assets/images/message-icon.svg" class="message-empty" alt="no comments"/>
            <p class="canvas__comments-not">No comments added</p>
          </div>
        </template>

        <template v-else-if="statusLoadComments === 'ok' && comments.length > 0">
          <ul class="uk-comment-list pxp-comment-list">

            <template v-for="(comment, index) in comments" :key="'comments-item-' + index">
              <li>
                <Comment :author-name="comment.authorUser.firstName + ' ' + comment.authorUser.lastName"
                         :avatar="avatarThumbnail(comment.authorUser)"
                         :content="comment.content"
                         :date="comment.createdAt"
                         :id="comment.id"
                         :show="show"/>
              </li>
            </template>
          </ul>
        </template>

        <template v-else>
          <p>Error</p>
        </template>
      </div>
    </template>

    <template v-slot:footer>
      <TextArea
          :id="'new-comments-for-' + id"
          name="new-comments"
          v-model="newComment"
          placeholder="Your comment"
      />
      <Button
          style-modifiers="success"
          text="Send comment"
          class="button-send-comment"
          @click="sendNewComment"
          :loading="sendingComment"
      />
    </template>

  </Card>
</template>

<script>
import {ref, reactive, onMounted, onUnmounted, onUpdated, watch, computed, inject, nextTick} from 'vue'
import Card from "@/components/UI-Kit/Card"
import {getComments, sendComment} from "@/api/comments";
import CircleLoader from "@/components/UI-Kit/loaders/CircleLoader";
import TextArea from "@/components/UI-Kit/TextArea";
import Button from "@/components/UI-Kit/Button";
import Comment from "@/components/Comments/Comment";



const commentSetup = function(props, {emit}) {
  const statusLoadComments = ref('load');
  const comments = ref([]);
  const newComment = ref("");
  const sendingComment = ref(false);

  getComments(props.contentType, props.id)
      .then((res) => {
        comments.value = reactive(res)
        statusLoadComments.value = 'ok'
      })
      .catch(() => {
        statusLoadComments.value = 'error'
      })

  const socket = inject('socket')

  socket.value.on('new_comment', (data) => {
    if (data.relatedSlug === `${props.contentType}:${props.id}`) {
      comments.value.push(data)
      emit("new-comment")
    }
  })

  socket.value.on('like_comment', (data) => {
    console.log(data) // TODO: like comment
  })



  const goBottom = function() {
    nextTick(() => {
      let elem = document.querySelector('#comments-all-' + props.id)
      let height = elem.scrollHeight
      elem.scrollTop = height
    })

  }

  const sendNewComment = function() {
    sendingComment.value = true
    sendComment(props.contentType, props.id, props.workspace, {
      content: newComment.value,
      related: [{
        refId: props.id,
        ref: props.contentType,
        field: "comments"
      }]
    }).then(() => {
      sendingComment.value = false
      newComment.value = ""
      goBottom()
    })
  };

  const deleteComment = function() {}; // TODO: deleteComment
  const editComment = function() {}; // TODO: editComment

  watch(() => props.show, (newValue) => {
    if (newValue) {
      goBottom()
    }
  })

  return {
    comments,
    sendNewComment,
    deleteComment,
    editComment,
    newComment,
    statusLoadComments,
    sendingComment
  };
};

export default {
  name: "CommentsCard",
  components: {Comment, CircleLoader, Card, Button, TextArea },
  props: {
    contentType: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: true
    },
    workspace: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: "comment"
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    avatarThumbnail: (user) => {
      if (
          user.avatar &&
          user.avatar.formats &&
          user.avatar.formats.thumbnail &&
          user.avatar.formats.thumbnail.url
      ) {
        return user.avatar.formats.thumbnail.url
      }
    }
  },
  setup: commentSetup
};
</script>

<style scoped lang="scss">
@import "~@/assets/styles/color-scheme.scss";
@import "~@/assets/styles/utility.scss";

.canvas__comments-not {
  font-size: 14px;
  line-height: 16px;
  text-align: center;

  color: #80869d;

  opacity: 0.8;
}


.comments-card {
  padding: 15px;
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
}
.message-empty {
  width: 64px;
  height: 64px;

  margin: auto;
  margin-top: 110px;
}

.pxp-comment-list {
  li {
    margin-top: 40px;
  }
}

.comments-all {
  @include suppress-scroll;
  max-height: calc(100vh - 400px);
  min-height: 295px;
  overflow-y: auto;
  margin-bottom: 20px;
  width: 100%;
}

.button-send-comment{
  width: 176px;
  height: 40px;
  float: left;
}

.comments-footer {
  background-color: $pxp-gray-accent-color-dimmer;
}

.no-comments {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-items: center;
  align-items: center;
}

.title {
  margin-bottom: 20px;
}
</style>
