import { ref, provide, computed } from "vue";

import axios from "axios";
import {io} from "socket.io-client";
import {loadStripe} from '@stripe/stripe-js';

import {getSubscriptionInfo} from "@/api/pay";

import {workspacesInitialization} from "@/setups/initWorkspaceSetup";
import {userInitialization} from "@/setups/initUserSetup";

export const appInitialization = function() {
  axios.defaults.baseURL = process.env.VUE_APP_BACKEND_SERVER;

  const { loadingUser, getUserData } = userInitialization();
  const { loadWorkspaces, getWorkspaces } = workspacesInitialization();
  const initLoad = ref(false)
  const subscriptionLoad = ref(false)
  const stripeLoad = ref(false)
  const stripe = ref({})
  const socket = ref()
  const subscription = ref({})
  const appLoad = computed(() => loadingUser.value || initLoad.value || subscriptionLoad.value || stripeLoad.value || loadWorkspaces.value)

  const appUpdateExist = ref(false)
  const appOffline = ref(false)

  const tokenStart = localStorage.getItem("tkn");

  document.addEventListener('appUpdated', (event) => {
    appUpdateExist.value = true
  }, {once: true})

  document.addEventListener('appOffline', (event) => {
    appOffline.value = true
  }, {once: true})

  const appUpdate = function () {
    appUpdateExist.value = false
    window.location.reload();
  }

  const appInit = function() {
    const token = localStorage.getItem("tkn")
    initLoad.value = true
    subscriptionLoad.value = true
    stripeLoad.value = true


    // authorization
    axios.defaults.headers.common["Authorization"] = token;
    getUserData()
    getWorkspaces()

    // socket setting
    socket.value = io(process.env.VUE_APP_BACKEND_SERVER, {
      extraHeaders: {
        authorization: token
      }
    })
    socket.value.on('init', () => {
      initLoad.value = false
    })


    // stripe setting
    loadStripe(process.env.VUE_APP_STRIPE_PK)
        .then((stripeObj) => {
          stripe.value = stripeObj
          stripeLoad.value = false
        })


    // subscription load
    getSubscriptionInfo()
        .then(data => {
          subscription.value = data
          subscriptionLoad.value = false
        })

  }

  if (tokenStart !== null) {
    appInit()
  }

  provide("socket", socket)
  provide("stripe", stripe)
  provide("subscription", subscription)

  provide('appInit', appInit)
  provide('appLoad', appLoad)

  return {
    appLoad,
    appUpdate,
    appUpdateExist,
    appOffline
  }
};
