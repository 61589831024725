import axios from "axios";

export async function createGoal(workspace, goalData) {
  try {
    const response = await axios.post("/goals", {
      workspace,
      ...goalData
    });

    return response.data;
  } catch {
    throw new Error();
  }
}

export async function deleteGoal(id, workspace) {
  try {
    const response = await axios.delete("/goals/" + id + "/" + workspace);

    return response.data;
  } catch {
    throw new Error();
  }
}

export async function getAllGoals(workspace) {
  try {
    const response = await axios.get("/goals", {
      params: {
        workspace
      }
    });

    return response.data;
  } catch {
    throw new Error();
  }
}

export async function updateGoal(id, workspace, goalData) {
  try {
    if (goalData.hasOwnProperty('workspace')) {
      delete goalData.workspace
    }
    const response = await axios.put("/goals/" + id, {
      workspace,
      ...goalData
    });

    return response.data;
  } catch {
    throw new Error();
  }
}
