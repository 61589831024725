<template>
  <DashboardLeftItem title="Current Goals" :edit-link="goals.length > 0? `/workspace/${workspaceId}/goals`: ''">
    <template v-if="status === 'load'">
      <CircleLoader dark />
    </template>
    <template v-else>
      <template v-if="goals.length > 0">
        <div class="goals-items">
          <template v-for="goal in goals" :key="'goal-dashboard-view' + goal.id">
            <GoalsView :goal="goal"/>
          </template>
        </div>
      </template>
      <template v-else>
        <Icon
            :icon="iconCurrentGoals"
            :size-height="48"
            :size-width="80"
            class="left-item__icon"
        ></Icon>
        <p class="dli__text">
          Let’s plan the right steps <br />
          to achieve your PhD
        </p>
        <Button
            style-modifiers="secondary-dark"
            text="Set goals"
            class="left-item__button current-goals"
            @click="toGoals"
        />
      </template>
    </template>

  </DashboardLeftItem>
</template>

<script>
import DashboardLeftItem from "@/components/Dashboard/DashboardLeftItem";
import { Icon as IconClass } from "@/components/UI-Kit/Helper classes/icon";
import Icon from "@/components/UI-Kit/Icon";
import Button from "@/components/UI-Kit/Button";
import { useRouter } from "vue-router";
import CircleLoader from "@/components/UI-Kit/loaders/CircleLoader";
import GoalsView from "@/components/Dashboard/GoalsView";
import {ref, reactive, computed, inject, watch} from "vue";
import {getAllGoals} from "@/api/goals";

const goalsInitialSetup = function(props) {
  const goals = reactive([]);
  const risks = reactive([]);
  const status = ref('load');
  const goalsRefs = computed(() => toRefs(goals));
  const risksRefs = computed(() => toRefs(risks));
  const socket = inject('socket');

  socket.value.on('deleted_goal', (data) => {
    const id = data.data
    let ind = goals.findIndex(item => item.id === id)
    if (ind === -1) {
      ind = risks.findIndex(item => item.id === id)
      if (ind !== -1)
        risks.splice(ind, 1)
    } else {
      goals.splice(ind, 1)
    }
  })

  socket.value.on('updated_goal', (data) => {

    const goalData = data.data
    let ind = -1
    if (goalData.type === 'goal') {

      ind = goals.findIndex((item) => item.id === goalData.id)

      if (ind !== -1) {
        goals.splice(ind, 1, reactive(goalData))
      }
    }
    else {
      ind = risks.findIndex((item) => item.id === goalData.id)

      if (ind !== -1) {
        risks.splice(ind, 1, reactive(goalData))
      }
    }
  })

  socket.value.on('created_new_goal', (data) => {

    const goalData = data.data

    let ind
    if (goalData.type === 'goal') {

      goals.push(reactive(goalData))
    }
    else {
      risks.push(reactive(goalData))
    }
  })

  const initGoals = function () {
    status.value = 'load'

    goals.splice(0, goals.length)
    risks.splice(0, risks.length)

    getAllGoals(props.workspaceId)
        .then((goalsResponse) => {
          goalsResponse.forEach((item) => {
            if (item.type === 'goal') {
              goals.push(reactive(item))
            } else {
              risks.push(reactive(item))
            }
          })
          status.value = 'ok'
        })
        .catch(() => {
          status.value = 'error'
        })
  }

  initGoals()

  watch(() => props.workspaceId, () => {
    initGoals()
  })
  return {
    goalsRefs,
    risksRefs,
    goals,
    risks,
    status
  };
};

export default {
  name: "DashboardLeftCurrentGoals",
  components: {
    GoalsView,
    CircleLoader,
    Button,
    Icon,
    DashboardLeftItem
  },
  props: {
    workspaceId: String
  },
  setup(props) {
    const iconCurrentGoals = new IconClass(
      require("@/assets/images/current-goals.svg"),
      "current goals",
      ""
    );
    const router = useRouter();

    const toGoals = function() {
      router.push({
        name: "Goals"
      });
    };

    return {
      iconCurrentGoals,
      toGoals,
      ...goalsInitialSetup(props)
    };
  }
};
</script>

<style scoped>
.dli__text {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 175%;
  text-align: left;
  color: #ffffff;

  opacity: 0.7;
}

.left-item__icon {
  margin: 0;
}

.left-item__button {
  border-radius: 32px;
  height: 40px;
  padding: 16px;
  font-weight: 600;
  font-size: 13px;
  color: #ffffff;
  margin: 0;
  line-height: 0;
}

.current-goals {
  width: 93px;
}

.goals-items {
  margin-bottom: 12px;
}
</style>
