import axios from "axios";

export async function myWorkspaceRequestApi() {
  const response = await axios.get("/workspaces/my");

  return response.data;
}

export async function createWorkspaceRequestApi(workspaceData) {
  const data = new FormData();

  if (workspaceData.workCover) {
    const cover = workspaceData.workCover
    delete workspaceData.workCover
    data.append("files.workCover", cover)
  }

  data.append("data", JSON.stringify(workspaceData))

  const response = await axios.post("/workspaces", data, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });

  return response.data;
}

export async function joinToWorkspace(workspace, socketId) {
  const response = await axios.post("/workspaces/join", {
    workspace,
    socketId
  });

  return response;
}

export async function leaveFromWorkspace(workspace, socketId) {
  const response = await axios.post("/workspaces/leave", {
    workspace,
    socketId
  });

  return response;
}

export async function inviteCollaboratorsRequestApi(workspace, invitedUsers) {
  const response = await axios.post("/workspaces/invite", {
    workspace,
    invitedUsers
  })

  return response.data
}

export async function getInCollaboratorsRequestApi(workspace, key) {
  const response = await axios.post("/workspaces/get-in-collaborators", {
    workspace: workspace,
    key: key
  })

  return response.data
}

export async function getCollaborators(workspace) {
  const response = await axios.get("/workspaces/collaborators/" + workspace);

  return response.data
}


export async function getWorkspace(workspace) {
  const response = await axios.get("/workspaces/" + workspace)

  return response.data
}

export async function updateWorkspaceAvatar(workspace, avatar) {
  const data = new FormData();
  if (typeof avatar !== 'undefined') {
    data.append("files.workCover", avatar);
  }

  data.append("data", JSON.stringify({}))

  console.log(data)

  const response = await axios.put("/workspaces/" + workspace, data, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  })
}

export async function updateWorkspace(workspace, data) {
  const response = await axios.put("/workspaces/" + workspace, data)

  return response.data
}