import axios from "axios";

export async function getComments(ct, id) {
    const response = await axios.get(`/comments/${ct}:${id}/flat`);

    return response.data;
}

export async function sendComment(ct, id, workspace, comment) {
    const response = await axios.post(`/comments/${ct}:${id}`, {
        workspace,
        ...comment
    });

    return response.data;
}

export async function editComment(ct, id, cid, workspace, comment) {
    const response = await axios.put(`/comments/${ct}:${id}/comment/${cid}`, {
        workspace,
        ...comment
    });

    return response.data;
}

export async function likeComment(ct, id, workspace, cid) {
    const response = await axios.patch(`/comments/${ct}:${id}/comment/${cid}/like`, {
        workspace
    });

    return response.data;
}