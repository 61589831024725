<template>
  <div class="uk-inline pxp__text-area-wrapper">
    <template v-if="label">
      <label :for="id" :class="[mode? 'mode-' + mode: '']">{{ label }}</label>
    </template>

    <textarea
      :id="id"
      :name="name"
      class="pxp__text-area uk-textarea"
      :value="modelValue"
      :placeholder="placeholder"
      :class="addTextAreaClasses"
      @input="$emit('update:modelValue', $event.target.value)"
      @focusout="checkValue()"
      @focusin="resetCheck()"
    />

    <div
      v-if="msg.length > 0"
      class="ti-help"
      :class="[error ? 'ti-help__error' : '']"
    >
      <p>{{ msg }}</p>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from "vue";
export default {
  name: "TextArea",
  props: {
    // v-model

    modelValue: {
      type: String
    },

    // textarea

    label: {
      type: [String, undefined],
      required: false,
      default: undefined
    },
    type: {
      type: String,
      default: "text"
    },
    id: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: false,
      default: ""
    },

    // mode

    mode: {
      type: String
    },

    // help
    validate: {
      type: Array,
      required: false,
      default: () => []
    },

    msgDefault: {
      type: String,
      default: ""
    }
  },
  setup(props) {
    const error = ref(false);
    const msg = ref("");
    const msgDefault = ref(props.msgDefault);
    msg.value = msgDefault.value;

    const addMode = mode => {
      return {
        textareaClass: "ta-textarea__" + mode
      };
    };

    let { textareaClass } = addMode(props.mode);

    const checkValue = async function() {
      if (props.modelValue.length > 0) {
        for (let v of props.validate) {
          let err = v(props.modelValue);
          if (err) {
            error.value = true;
            msg.value = err;
            break;
          }
        }
      }
    };

    const resetCheck = function() {
      error.value = false;
      msg.value = msgDefault.value;
    };

    onMounted(() => {
      if (props.mode === "not-decorate") {
        let element = document.querySelector("#" + props.id);
        element.focus();
      }
    });

    return {
      checkValue,
      resetCheck,
      error,
      msg,
      addTextAreaClasses: computed(
        () => textareaClass + (error.value ? " ta-textarea__error" : "")
      )
    };
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/styles/color-scheme.scss";

.pxp__text-area-wrapper {
  width: 100%;
}

.pxp__text-area {
  border-radius: 10px;
  width: 100%;
  min-width: 100%;
  height: 100%;
  min-height: 100px;
  box-shadow: 0 0 4px #cccccc;
  border: none;
  padding: 10px;
  box-sizing: border-box;
  background-color: white;
  margin-bottom: 15px;
}

.ta-textarea__not-decorate,
.ta-textarea__not-decorate:focus,
.ta-textarea__not-decorate:active {
  box-shadow: none !important;
  border: none !important;
  color: black;
}

.pxp__text-area:focus {
  border: 1px solid #1d85d0;
}

.ta-textarea__error {
  border: 1px solid #e8614b !important;
}

.ta-help {
  background-color: white;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #333333;
  opacity: 0.8;
  margin-top: 20px;
  text-align: left;
  p {
  }
}

.ta-help__error {
  color: #e8614b;
}

.ta-textarea__account {
  background-color: $pxp-white-color;
  color: #333333;
  border: 1px solid $pxp-gray-color-lighten;
  border-radius: 4px;
  &:focus {
    border: 1px solid $pxp-blue-accent-color-brighter;
  }
  box-shadow: none;
}

label {
  &.mode-account {
    color: #333333;
    font-style: normal;
    font-weight: 500;
  }
}
</style>
