<template>
  <div class="checker" :class="'mode-' + mode">
    <template
      v-for="(checkItem, index) in checkList"
      :key="`checker-${id}-item-${index}`"
    >
      <div class="checker-item" :class="[checkItem.value.toString(), 'mode-' + mode]" />
    </template>
  </div>
</template>

<script>
import { computed } from "vue";

export default {
  name: "Checker",
  props: {
    mode: {
      type: String,
      required: false,
      default: 'onboarding'
    },
    id: {
      type: String,
      required: true
    },
    checkList: {
      type: Array,
      required: true,
      validator: checkList =>
        checkList.reduce((flag, item) => {
          if (flag) {
            return (
              typeof item.value === "boolean" ||
              typeof item.value === "undefined"
            );
          }
        }, true)
    }
  },
  setup() {
    const itemValueToString = function(item) {
      return computed(() => {
        return typeof item.value === "undefined"
          ? "undefined"
          : item.value.toString();
      });
    };
    return {
      itemValueToString
    };
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/styles/color-scheme.scss";

.checker {
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  overflow: hidden;
  background-color: $pxp-blue-color-lighten;

  &.mode-rq {
    background-color: rgba(0,0,0,0);
  }
}

.checker-item {

  height: 100%;
  width: 100%;

  &.mode-rq {
    height: 8px;
    width: 8px;
    border-radius: 4px;
    margin-right: 2px;
  }

  &.true {
    background-color: $pxp-green-accent-color-brighter;
    &.mode-rq {
      background-color: $pxp-green-color;
    }
  }

  &.false {
    background-color: $pxp-blue-color-lighten;
    &.mode-rq {
      background-color: #CCCFDB;
    }
  }

  &.undefined {
    background-color: $pxp-blue-color-lighten;
  }
}
</style>
