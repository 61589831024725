<template>
  <p class="cm__question">Embarking on a journey for PhD?</p>
  <p class="cm__answer">
    <b>Page x page</b> is a pathway to PhD completion.<br />
    Let’s answer a few questions to get started.
  </p>
</template>

<script>
export default {
  name: "CoveringMessage"
};
</script>

<style scoped>
.cm__question {
  font-weight: bold;
  color: #1fe788;
  font-size: 32px;
  line-height: 38px;
}

.cm__answer {
  color: white;
  font-size: 24px;
  line-height: 150.6%;
}
</style>
