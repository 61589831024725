<template>
  <p>{{ code }}</p>
</template>

<script>
export default {
  name: "NewPassword",
  props: {
    code: {
      type: String,
      required: true
    }
  }
};
</script>

<style scoped></style>
