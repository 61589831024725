<template>
  <template v-if="relateTask">
    <div class="actual-task-card">
      <div class="actual-task">
        <div class="content">
          <h2>{{relateTask.name}} <p v-if="!isCompleted" class="timer">{{timer}}</p></h2>

          <p v-if="checkPostpone(index)" class="postpone">postponed</p>

          <p v-if="todoTask.plan.length > 0" class="plan">plan: {{todoTask.plan}}</p>
        </div>
        <div class="action">
          <template v-if="isCompleted">
            <div class="completed">
              <IconNew name="smile" class="icon"/>
              <p>
                <template v-if="todoTask.needPomodoro === -1">
                  Complete
                </template>
                <template v-else>
                  {{needTimer}}
                </template>

              </p>
            </div>
            <ButtonNew text="Add results" mode="text" size="s" class="add-results-button" @click="showAddResult = true"/>
          </template>
          <template v-else>
            <ButtonNew icon-name="ok-complete-green" icon-position="left" size="s" mode="default" class="complete-button" text="Mark complete" @click="completeTTTask"/>
          </template>
          <div class="uk-inline">
            <MoreHorizontal/>
            <div
                class="actual-task-more"
                uk-dropdown="offset: 16; animation: uk-animation-slide-bottom-small; duration: 300; mode:hover; delay-hide: 300;"
            >
              <ul class="uk-nav uk-dropdown-nav">
                <li><router-link :to="`/workspace/${workspaceId}/sprints`">Go to Sprint</router-link></li>
                <template v-if="!isCompleted">
                  <li><a @click="postponeTTTask">Postpone</a></li>
                </template>
                <li><a @click="removeTTTask">Remove</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <AddResultTaskModal :index="index" :workspace-id="workspaceId" :todo-task="todoTask" v-model:show="showAddResult"/>
    </div>
  </template>
  <template v-else>
    <CircleLoader class="loader"/>
  </template>
</template>

<script>
import Card from "@/components/UI-Kit/Card";
import ButtonNew from "@/components/UI-Kit/ButtonNew";
import IconNew from "@/components/UI-Kit/IconNew";
import MoreHorizontal from "@/components/UI-Kit/MoreHorizontal";
import {ref, reactive, computed, watch, inject} from "vue";
import CircleLoader from "@/components/UI-Kit/loaders/CircleLoader";
import moment from "moment";
import momentDuration from "moment-duration-format";
import AddResultTaskModal from "@/components/To do today/AddResultTaskModal";

export default {
  name: "ActualTask",
  components: {AddResultTaskModal, CircleLoader, IconNew, ButtonNew, Card, MoreHorizontal},
  props: {
    todoTask: Object,
    index: Number,
    workspaceId: String
  },
  setup (props, {emit}) {
    const NOT_DATE = Date.parse("2000-09-10T00:00:00.000Z")

    const tasks = inject('tasks')
    const rqs = inject('rqs')

    const relateTask = computed(() => {
      if (!props.todoTask.hasOwnProperty('type') && props.todoTask.task && tasks[props.todoTask.task]) {
        let task = tasks[props.todoTask.task]
        return {
          name: task.name,
          rq: typeof task.researchQuestion === "object"? task.researchQuestion.name: rqs[task.researchQuestion].name
        }
      } else {
        return undefined
      }
    })

    const deleteTTTaskByIndex = inject('deleteTTTaskByIndex')
    const postponeTTTaskByIndex = inject('postponeTTTaskByIndex')
    const completeTTTaskByIndex = inject('completeTTTaskByIndex')
    const checkPostpone = inject("checkPostponeByIndex")

    const timer = computed(() => {
      const t = moment.duration(props.todoTask.pomodoro * 1500, 'seconds').format('h:m')
      if (t.split(":").length === 2)
        return `~ ${t.split(":")[0]} h. ${t.split(":")[1]} min.`
      else
        return `~ ${t.split(":")[0]} min.`
    })
    const isCompleted = computed(() => props.todoTask.timeFinish && Date.parse(props.todoTask.timeFinish) !== NOT_DATE)
    const postponeTTTask = function() {
      postponeTTTaskByIndex(props.index)
    }

    const removeTTTask = function() {
      deleteTTTaskByIndex(props.index)
    }

    const completeTTTask = function() {
      completeTTTaskByIndex(props.index)
    }

    const showAddResult = ref(false)

    const needTimer = computed(() => {
      const t = moment.duration(props.todoTask.needPomodoro * 1500, 'seconds').format('h:m')
      if (t.split(":").length === 2)
        return `~ ${t.split(":")[0]} h. ${t.split(":")[1]} min.`
      else
        return `~ ${t.split(":")[0]} min.`
    })

    return {
      relateTask,
      timer,
      needTimer,
      removeTTTask,
      completeTTTask,
      postponeTTTask,
      checkPostpone,
      isCompleted,
      showAddResult
    }
  }
}
</script>

<style scoped lang="scss">
.actual-task-card {
  margin: 16px 0;
  padding: 16px;
  background: #FFFFFF;
  box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.actual-task {
  .content {
    text-align: left;
    .plan {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 125%;
      /* identical to box height, or 17px */


      color: #000000;

      opacity: 0.5;
    }

    margin-bottom: 16px;
  }

  .action {
    text-align: left;
    display: flex;
    flex-direction: row;
    align-items: center;

    .complete-button {
      width: 124px;
      margin-right: 16px;
    }

    .add-results-button {
      width: 84px;
      margin-right: 4px;
    }

    .completed {
      cursor: default;
      background: rgba(12, 172, 96, 0.1);
      border-radius: 4px;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 4px 6px;
      margin-right: 4px;
      .icon {
        width: 16px;
        height: 16px;
        margin-right: 4px;
      }
      p {
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 125%;
        /* identical to box height, or 15px */


        color: #0CAC60;

      }
    }
  }
}



.actual-task-more {
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}

.loader {
  display: block;
  margin-bottom: 16px;
}
.postpone {
  font-weight: 500;
  font-size: 10px;
  font-style: italic;
  line-height: 12px;
  text-transform: uppercase;
  margin: 0 0 4px 0;
  color: #80869D;

  opacity: 0.8;
}

.timer {
  float: right;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 125%;
  /* identical to box height, or 15px */

  text-align: right;

  color: #80869D;
}


</style>