<template>
  <template v-if="icon.baseIcon.length === 0">
    <span class="uk-icon uk-icon-image pxp__icon" :style="styleSetting" />
  </template>
  <template v-else>
    <span
      class="uk-icon"
      :uk-icon="'icon: ' + icon.baseIcon"
      :style="styleSetting"
    />
  </template>
</template>

<script>
import { Icon } from "@/components/UI-Kit/Helper classes/icon";

export default {
  name: "Icon",
  props: {
    icon: {
      type: Icon,
      required: true
    },
    size: {
      type: Number,
      required: false,
      default: 20
    },
    sizeHeight: {
      type: [Number, undefined],
      required: false,
      default: undefined
    },
    sizeWidth: {
      type: [Number, undefined],
      required: false,
      default: undefined
    }
  },
  setup(props) {
    const styleSetting = {
      height: props.sizeHeight ? props.sizeHeight + "px" : props.size + "px",
      width: props.sizeWidth ? props.sizeWidth + "px" : props.size + "px"
    };

    if (props.icon.src.length !== 0)
      styleSetting.backgroundImage = "url(" + props.icon.src + ")";

    return {
      styleSetting
    };
  }
};
</script>

<style>
.pxp__icon {
  margin: auto;
}
</style>
