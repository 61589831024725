<template>
  <div class="dashboard__left-item">
    <h3>{{ title }}
    <template v-if="editLink.length">
      <div class="edit" @click="openEditLink">
        <IconNew name="edit-light-blue" class="icon"/>
        <p>Edit</p>
      </div>
    </template></h3>
    <slot> </slot>
  </div>
</template>

<script>
import IconNew from "@/components/UI-Kit/IconNew";
import {useRouter} from "vue-router";
export default {
  name: "DashboardLeftItem",
  components: {IconNew},
  props: {
    title: {
      required: true,
      type: String
    },
    editLink: {
      type: String,
      default: ""
    }
  },
  setup(props) {
    const router = useRouter()

    const openEditLink = function () {
      router.push(props.editLink)
    }

    return {
      openEditLink
    }
  }
};
</script>

<style scoped lang="scss">
.dashboard__left-item {
  h3 {
    font-weight: bold;
    font-size: 22px;
    line-height: 26px;
    text-align: left;

    color: #ffffff;
  }
  text-align: left;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  justify-items: left;

  padding: 25px;

  .edit {
    float: right;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 4px;
    cursor: pointer;
    p {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 14px;
      /* identical to box height */


      color: #C0CCFF;
    }
    .icon {
      width: 12px;
      height: 12px;
      margin-right: 4px;
    }
  }
}
</style>
