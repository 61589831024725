<template>
  <span :uk-spinner="'ratio: ' + ratio" class="pxp__circle-loader" :class="[dark? 'mode-dark': '']" />
</template>

<script>
export default {
  name: "CircleLoader",
  props: {
    ratio: {
      type: Number,
      required: false,
      default: 1
    },
    dark: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped lang="scss">
.pxp__circle-loader {

  &.mode-dark {
    color: white
  }
}
</style>
