<template>
  <div class="offline-block">
    <p class="offline-block__message">🎉 Offline mode!</p>
  </div>
</template>

<script>
export default {
  name: "OfflineMessage"
}
</script>

<style scoped lang="scss">
@import "~@/assets/styles/color-scheme";

.offline-block {
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  background-color: $pxp-white-accent-color-brighter;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 4px 16px;
  margin: 0;
}

.offline-block__message {
  font-weight: bold;
}
</style>