<template>
  <div class="loader-wrapper">
    <div class="cssload-piano">
      <div class="cssload-rect1"></div>
      <div class="cssload-rect2"></div>
      <div class="cssload-rect3"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ButtonLoader"
};
</script>

<style scoped lang="scss">
.loader-wrapper {
  height: 16px;
  padding: 0;
  display: flex;
  box-sizing: border-box;
}
.cssload-piano {
  display: flex;
  margin: auto;
  box-sizing: border-box;
  justify-self: center;
  padding: 0;
  flex-direction: row;
}
.cssload-piano > div {
  height: 12px;
  width: 3px;
  margin-top: 0;
  animation: stretchdelay 1.38s infinite ease-in-out;
}
.cssload-piano .cssload-rect2 {
  animation-delay: -1.15s;
  margin-left: 5px;
  margin-right: 5px;
}
.cssload-piano .cssload-rect3 {
  animation-delay: -0.92s;
}

@keyframes stretchdelay {
  0%,
  40%,
  100% {
    transform: scaleY(0.6);
    background-color: rgba(255, 255, 255, 0.6);
    // box-shadow: 0 0 0 rgba(10,10,10,0.1);
  }
  20% {
    transform: scaleY(1);
    background-color: rgba(255, 255, 255, 1);
    // box-shadow: 0 5px 6px rgba(10,10,10,0.4);
  }
}
</style>
