<template>
  <div
    :id="id"
    class="uk-list list-editor-block"
    :class="'mode-' + mode"
    :uk-sortable="'handle: .sortable-handle' + (typeof sortableGroup === 'undefined'? '': '; group: ' + sortableGroup)"
    @keypress.enter="addNewItem"

  >
    <template v-for="(itemList, index) in list">
      <div class="list-editor-item" :class="[ 'mode-' + mode  ]"
           @focusin="activeInput = index"
           @focusout="activeInput = 0"
      >
        <!--
        <IconNew name="drag" class="sortable-handle" />

        <TextEditorBlock
          :id="getIdFromIndex(index)"
          targ="inline"
          v-model:item="itemList.value"
          mode="list-item"
          :class="[index === list.length - 1? 'end': '']"
          @delete-item="deleteItem(index)"
          @updated="updateItem(index)"
        />-->
        <template v-if="index === 0">
          <TextInputNew
              :id="getIdFromIndex(index)"
              v-model="list[index].value"
              :mode="mode"
              :class="[index === list.length - 1? 'end': '']"
              :size="size"
              :placeholder="placeholder"
          />
        </template>
        <template v-else>
          <div class="wrapper-removed">
            <TextInputNew
                :id="getIdFromIndex(index)"
                v-model="list[index].value"
                :mode="mode"
                :class="[index === list.length - 1? 'end': '']"
                :size="size"
                placeholder="Type here"
            />
            <template v-if="mode === 'canvas-list'">
              <IconNew
                  :name="'delete-trash-icon'"
                  class="can-removed"
                  @click="deleteItem(index)"
              />
            </template>
            <template v-else>
              <IconNew
                  name="delete"
                  class="can-removed"
                  @click="deleteItem(index)"
              />
            </template>
          </div>
        </template>


      </div>
    </template>
  </div>
</template>

<script>
import { isRef, watch, ref, toRefs, computed, onMounted, toRef, onUpdated } from "vue";
import TextEditorBlock from "@/components/UI-Kit/TextEditorBlock";
import UIkit from "uikit";
import IconNew from "@/components/UI-Kit/IconNew";
import TextInputNew from "@/components/UI-Kit/TextInputNew";

export default {
  name: "ListEditorBlock",
  components: {TextInputNew, IconNew, TextEditorBlock },
  props: {
    id: {
      type: String
    },
    modelValue: {},
    placeholder: {
      type: String,
      required: false
    },
    order: {
      type: Array,
      required: false,
      default: []
    },
    new: {
      type: Boolean
    },
    mode: {
      type: String,
      default: 'default'
    },
    sortableGroup: {
      type: [String || undefined],
      default: undefined
    },
    size: {
      type: String,
      default: 's'
    }
  },
  setup: function(props, { emit }) {
    const list = computed(() => toRefs(props.modelValue))
    const addedNew = ref(false)
    const activeInput = ref(0)
    const activeClose = ref(0)

    const updateItem = function(index) {
      props.modelValue[index] = list.value[index].value
      emit("update:modelValue", props.modelValue);
    }

    const getIdFromIndex = function(index) {
      return `leb-${props.id}-item-${index}`;
    };
    const getIndexFromId = function(id) {
      const idSplited = id.split("-");
      return idSplited.pop();
    };

    let listEditor = undefined;
    let listEditorUI = undefined;

    const initLE = function() {
      listEditor = document.querySelector("#" + props.id);
      listEditorUI = UIkit.sortable(listEditor);
      UIkit.util.on("#" + listEditor.id, "stop", function(event) {
        const newOrderNodeList = listEditor.querySelectorAll(".text-editor-block");
        const order = [];
        for (let i = 0; i < newOrderNodeList.length; i++) {
          order.push(Number.parseInt(getIndexFromId(newOrderNodeList[i].id)));
        }
        setOrder(order);
      });
    }

    onUpdated(() => {
      if (addedNew.value) {
        document.querySelector('#' + getIdFromIndex(props.modelValue.length - 1)).focus()
        addedNew.value = false
      }
      initLE()
    })

    const addNewItem = function() {
      props.modelValue.push("");
      props.order.push(props.modelValue.length - 1);
      emit("update:modelValue", props.modelValue);
      emit("update:order", props.order);
      addedNew.value = true
    };

    const setOrder = function(order) {
      emit("update:order", order);
    };

    const addNewItemByActivator = function() {
      addNewItem();
      emit("update:new", false);
    };

    const deleteItem = function(index) {
      props.modelValue.splice(index, 1);
      emit("update:modelValue", props.modelValue);
      activeInput.value = 0;
    };

    watch(
      () => props.new,
      newActivator => {
        if (newActivator) {
          addNewItemByActivator();
        }
      }
    );

    return {
      list,
      addNewItem,
      deleteItem,
      getIdFromIndex,
      updateItem,
      activeInput,
      activeClose
    };
  }
};
</script>

<style scoped lang="scss">

@import "~@/assets/styles/color-scheme.scss";

.list-editor-item {

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  justify-items: left;
  text-align: left;
  box-sizing: border-box;
  padding: 0;
  margin: 0;

  &.mode-default {
    min-height: 20px;
    .sortable-handle {

    }
  }

  &::before {
    width: 0px;
  }
}

.sortable-handle {
  width: 10px;
  height: 10px;
  margin: auto;
  margin-right: 10px;
}

.list-editor-block {
  width: 100%;
  display: flex;
  flex-direction: column;
  .mode-default {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
  }
}

.wrapper-removed {
  position: relative;
  width: 100%;
}

.can-removed {
  position: absolute;
  cursor: pointer;
  top: 0;
  right: 0;
  width: 10px;
  height: 10px;
  margin: 12px;
}
</style>
