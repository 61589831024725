import axios from "axios";

// Sprint

export async function getActualTodoTask(workspace) {
    const response = await axios.get('/todo-todays/actual', {
        params: {
            workspace
        }
    })

    return response.data
}

export async function createTodoTask(workspace, data) {
    const response = await axios.post('/todo-todays', {
        data,
        workspace
    })

    return response.data
}

export async function updateTodoTask(id, workspace, data) {

    const response = await axios.put('/todo-todays/' + id, {
        workspace,
        data
    })

    return response.data
}

export async function deleteTodoTask(id, workspace) {
    const response = await axios.delete('/todo-todays/' + id, {
        params: {
            workspace
        }
    })

    return response.data
}
export async function postponeTodoTask(id, workspace) {
    // add time for 00:00
    let time = new Date()
    time.setUTCHours(23,59, 59, 999 )
    time = time.toISOString()

    const response = await axios.post('/todo-todays/postpone', {
        workspace,
        id,
        time
    })

    return response.data
}
export async function completeTodoTask(id, workspace) {
    let time = (new Date()).toISOString()

    const response = await axios.post('/todo-todays/complete', {
        workspace,
        id,
        time
    })

    return response.data
}