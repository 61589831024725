<template>
  <template v-if="!removeHeader">
    <h3 class="invite-form__question">Who do you want to invite?</h3>
  </template>
  <TextArea
    id="invite-form"
    name="invite-form"
    placeholder="Emails of people you want to invite"
    v-model="modelValue"
    :validate="emailValidate"
    msg-default="Up to 3 people. Separate emails by commas"
  >
  </TextArea>
</template>

<script>
import TextArea from "@/components/UI-Kit/TextArea";
import { ref, watch } from "vue";
import * as emailValidator from "email-validator";

export default {
  name: "inviteForm",
  components: { TextArea },
  props: {
    modelValue: {
      type: String
    },
    removeHeader: {
      type: Boolean,
      default: false
    }
  },
  setup(props, {emit}) {

    const emailValidate = [
      email => {
        let flag = true;
        let emails = email.split(", ");
        for (let em of emails) {
          if (!emailValidator.validate(em)) {
            flag = false;
          }
        }

        return flag ? "" : "Looks like one of the emails is invalid";
      },
      email => {
        let emails = email.split(", ");
        return emails.length > 3 ? "Up to 3 people" : "";
      }
    ];

    watch(() => props.modelValue, (newValue) => {
      emit("update:modelValue", newValue)
    })

    return {
      emailValidate
    };
  }
};
</script>

<style scoped>
.invite-form__question {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 16px;
  color: #13246e;
}
</style>
