<template>
  <div class="js-upload uk-placeholder uk-text-center image-upload" :id="'avatar-upload-' + id">
    <span uk-icon="icon: cloud-upload"/>
    <br>
    <br>
    <span class="uk-text-middle">Attach images by dropping them here or</span>
    <br>
    <div uk-form-custom>
      <input type="file" multiple>
      <span class="uk-link">selecting one</span>
    </div>
  </div>

  <div class="avatar-editor" v-show="fileLoaded && (savedImageStatus !== 'saved')">
    <div class="avatar-editor__avatar" :id="'avatar-editor__avatar-' + id">


    </div>
    <div class="avatar-editor__setting">
      <div :id="'preview-' + id" class="avatar-editor__preview" :class="[rounded? 'rounded': '']"/>
      <h3>Rotation: {{rotation}}°</h3>
      <Range :min="0" :max="360" v-model="rotation" :step="1" class="range"/>
      <ButtonNew mode="dark" size="m" text="Save" icon-name @click="saveImage" :loading="savedImageStatus === 'progress'"/>
    </div>
  </div>

  <div class="avatar-editor-view" v-show="(savedImageStatus === 'saved')">
    <img :id="'view-' + id" :class="[rounded? 'rounded': '']">
  </div>
</template>

<script>
import Range from "@/components/UI-Kit/Range";
import ButtonNew from "@/components/UI-Kit/ButtonNew";
import 'cropperjs/dist/cropper.css';
import Cropper from "cropperjs";
import { ref, onMounted, watch } from 'vue';
import TextInputNew from "@/components/UI-Kit/TextInputNew";
import UIkit from "uikit"

const setup = function (props, {emit}) {
  const rotation = ref(0)
  const croppImage = ref(undefined)
  const fileLoaded = ref(false)
  const savedImageStatus = ref('default')

  /*
  if (typeof props.previewUrl !== 'undefined') {
    savedImageStatus.value = 'saved'
    const viewImg = document.querySelector(`img#view-${props.id}`)
    viewImg.src = props.previewUrl
  }
  */

  watch(rotation, (newValue) => {
    croppImage.value.rotateTo(newValue)
  })

  onMounted(() => {
    const wrapperImg = document.querySelector('#avatar-editor__avatar-' + props.id)
    const previewImg = document.querySelector('#preview-' + props.id)
    const uploadId = '#avatar-upload-' + props.id;

    UIkit.upload(uploadId, {
      url: '',
      multiple: false,
      method: () => false,

      beforeAll: function (el, files) {
        fileLoaded.value = false
        savedImageStatus.value = 'edit'

        wrapperImg.innerHTML = `<img id="${props.id}"/>`
        const img = document.querySelector(`img#${props.id}`)

        const fr = new FileReader()

        fr.onload = (event) => {
          img.src = event.target.result
          croppImage.value = new Cropper(img, {
            aspectRatio: 1 / 1,
            viewMode: 2,
            dragMode: 'move',
            preview: previewImg,
          });
          fileLoaded.value = true;
        }
        fr.readAsDataURL(files[0])
      }
    })
  })

  const saveImage = function () {
    savedImageStatus.value = 'progress'
    croppImage.value.getCroppedCanvas().toBlob((blob) => {
      console.log(blob)
      emit("update:modelValue", blob)

      const viewImg = document.querySelector(`img#view-${props.id}`)

      const urlCreator = window.URL || window.webkitURL
      viewImg.src = urlCreator.createObjectURL(blob)

      savedImageStatus.value = 'saved'
    })
  }

  return {
    rotation,
    fileLoaded,
    saveImage,
    savedImageStatus
  }
}

export default {
  name: "AvatarEditor",
  props: {
    id: String,
    size: {
      type: Number,
      default: 300
    },
    modelValue: {
      type: Object
    },
    rounded: {
      type: Boolean,
      default: true
    },
    previewUrl: {
      type: [String || undefined],
      default: undefined
    }
  },
  components: {
    TextInputNew,
    Range,
    ButtonNew,
  },
  setup
}
</script>

<style scoped lang="scss">
@import "~@/assets/styles/color-scheme.scss";

.avatar-editor {
  display: grid;
  grid-template-areas:
      "avatar setting";
  justify-items: center;


  &__avatar {
    grid-area: avatar;
    width: 300px;
    height: 300px;
    box-sizing: border-box;

  }

  &__setting {
    grid-area: setting;

    range {
      margin: 0;
    }
  }

  margin-bottom: 20px;
}

.image-upload, .avatar-editor {
  background-color: $pxp-white-color;
  width: 100%;
  border-radius: 4px;
  border: 1px solid $pxp-gray-color-lighten;
  &:focus {
    border: 1px solid $pxp-blue-accent-color-brighter;
  }
  margin-top: 0px;

  padding: 40px 15px;
}



.uk-progress {
  border-radius: 4px;
}

.cropper-view-box,
.cropper-face {
  border-radius: 50%;
}

.avatar-editor__preview,
.avatar-editor-view img {
  width: 150px;
  height: 150px;
  overflow: hidden;
  border-radius: 15%;
  margin-bottom: 35px;
  box-shadow: 0 0 4px lightgray;
  &.rounded {
    border-radius: 50%;
  }
}


.avatar-editor-view img {
  border: 1px solid $pxp-gray-color-lighten;
  margin: 20px auto;
  justify-self: center;
}
</style>