import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import NewPassword from "@/views/NewPassword";
import { checkAuth } from "@/setups/authSetup";

const isAuth = (to, from, next) => {
  if (checkAuth()) {
    next();
  } else {
    next({
      name: "Auth"
    });
  }
};

const isNotAuth = (to, from, next) => {
  if (!checkAuth()) {
    next();
  } else {
    next({
      name: "WorkspaceRedirect"
    });
  }
};

const routes = [
  {
    path: "/",
    redirect: "/workspace"
  },
  {
    path: "/create-new-password",
    name: "NewPassword",
    props: route => {
      console.log("created");
      return {
        code: route.query.code
      };
    },
    component: NewPassword //() => import("../views/NewPassword")
  },
  {
    path: "/workspace",
    name: "WorkspaceRedirect",
    beforeEnter: isAuth,
    component: () => import("../views/WorkspaceRedirect")
  },
  {
    path: "/workspace/:workspaceId",
    name: "Workspace",
    beforeEnter: isAuth,
    props: true,
    component: () => import("../views/Workspace"),
    children: [
      {
        path: "",
        beforeEnter: isAuth,
        props: true,
        redirect: {
          name: "Dashboard"
        }
      },
      {
        path: "dashboard",
        name: "Dashboard",
        props: true,
        beforeEnter: isAuth,
        component: Dashboard
      },
      {
        path: "goals",
        name: "Goals",
        beforeEnter: isAuth,
        props: true,
        component: () => import("../views/Goals")
      },
      {
        path: "course",
        name: "Course",
        props: true,
        component: () => import("../views/Course")
      },
      {
        path: "roadmap",
        name: "Roadmap",
        beforeEnter: isAuth,
        props: true,
        component: () => import("../views/Roadmap")
      },
      {
        path: "sprints",
        name: "Sprints",
        beforeEnter: isAuth,
        props: true,
        component: () => import("../views/Sprints")
      },
      {
        path: "billing",
        name: "Billing",
        beforeEnter: isAuth,
        props: true,
        component: () => import("../views/Billing")
      },
      {
        path: "account",
        name: "Account",
        beforeEnter: isAuth,
        props: true,
        component: () => import("../views/Account"),
        children: [
          {
            path: "",
            beforeEnter: isAuth,
            props: true,
            redirect: {
              name: "AccountWorkspaces"
            }
          },
          {
            path: "workspaces",
            name: "AccountWorkspaces",
            beforeEnter: isAuth,
            props: true,
            component: () => import("../components/Account/AccountWorkspaces")
          },
          {
            path: "me",
            name: "AccountPersonal",
            beforeEnter: isAuth,
            props: true,
            component: () => import("../components/Account/AccountPersonal")
          },
          {
            path: "billing",
            name: "AccountBilling",
            beforeEnter: isAuth,
            props: true,
            component: () => import("../components/Account/AccountBilling")
          }
        ]
      },
    ]
  },
  {
    path: "/onboarding",
    name: "Onboarding",
    beforeEnter: isAuth,
    component: () => import("../views/Onboarding")
  },
  {
    path: "/auth",
    name: "Auth",
    beforeEnter: isNotAuth,
    component: () => import("../views/Auth")
  },
  {
    path: "/join/:workspaceId",
    name: "JoinToWorkspace",
    props: (route) => {
      return {
        workspaceId: route.params.workspaceId,
        workspaceKey: route.query.key
      }
    },
    component: () => import("../views/JoinToWorkspace")
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
