<template>
  <div class="uk-card pxp__card" :class="cardClasses">
    <template v-if="cover !== undefined">
      <div :class="'uk-card-media-' + coverAlign" class="uk-cover-container">
        <img :src="cover.src" :alt="cover.alt" uk-cover class="cover" />
      </div>
    </template>
    <div class="card-content-wrapper">
      <div class="uk-card-header uk-margin-remove" :class="[paddingNotRemove? '': 'uk-padding-remove']">
        <slot name="header" />
      </div>
      <div class="uk-card-body uk-margin-remove" :class="[paddingNotRemove? '': 'uk-padding-remove']">
        <slot name="body" />
      </div>
      <div class="uk-card-footer uk-margin-remove" :class="[paddingNotRemove? '': 'uk-padding-remove']">
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { Image } from "@/components/UI-Kit/Helper classes/image";

export default {
  name: "Card",
  props: {
    styleModifiers: {
      type: String,
      required: false,
      default: "default",
      validator: mode =>
        ["default", "primary", "secondary"].indexOf(mode) !== -1
    },
    isHover: {
      type: Boolean,
      required: false,
      default: false
    },
    size: {
      type: String,
      required: false,
      default: "default",
      validator: mode => ["default", "small", "large"].indexOf(mode) !== -1
    },
    cover: {
      type: [Image, undefined],
      required: false,
      default: undefined
    },
    coverAlign: {
      type: String,
      required: false,
      default: "top",
      validator: mode => ["left", "right", "top", "bottom"].indexOf(mode) !== -1
    },
    paddingNotRemove: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup(props) {
    const cardClasses = computed(() => {
      return [
        "uk-card-" + props.styleModifiers,
        props.isHover ? "uk-card-hover" : "",
        props.size !== "default" ? "uk-card-" + props.size : ""
      ];
    });

    return {
      cardClasses
    };
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/styles/utility.scss";
.pxp__card {
  @include suppress-scroll;
  box-sizing: border-box;
  border-radius: 10px;
  padding-bottom: 0;
}

.card-content-wrapper, .uk-card-body, .uk-card-header, .uk-card-footer {
  width: 100%;
}
</style>
