<template>
  <svg class="handler" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0)">
      <path d="M6.5 6C6.5 6.82843 7.17157 7.5 8 7.5C8.82843 7.5 9.5 6.82843 9.5 6C9.5 5.17157 8.82843 4.5 8 4.5C7.17157 4.5 6.5 5.17157 6.5 6Z"/>
      <path d="M6.5 10.5C6.5 11.3284 7.17157 12 8 12C8.82843 12 9.5 11.3284 9.5 10.5C9.5 9.67157 8.82843 9 8 9C7.17157 9 6.5 9.67157 6.5 10.5Z"/>
      <path d="M6.5 1.5C6.5 2.32843 7.17157 3 8 3C8.82843 3 9.5 2.32843 9.5 1.5C9.5 0.671573 8.82843 -2.93554e-08 8 -6.55671e-08C7.17157 -1.01779e-07 6.5 0.671573 6.5 1.5Z"/>
      <path d="M2.5 6C2.5 6.82843 3.17157 7.5 4 7.5C4.82843 7.5 5.5 6.82843 5.5 6C5.5 5.17157 4.82843 4.5 4 4.5C3.17157 4.5 2.5 5.17157 2.5 6Z"/>
      <path d="M2.5 10.5C2.5 11.3284 3.17157 12 4 12C4.82843 12 5.5 11.3284 5.5 10.5C5.5 9.67157 4.82843 9 4 9C3.17157 9 2.5 9.67157 2.5 10.5Z"/>
      <path d="M2.5 1.5C2.5 2.32843 3.17157 3 4 3C4.82843 3 5.5 2.32843 5.5 1.5C5.5 0.671573 4.82843 -2.93554e-08 4 -6.55671e-08C3.17157 -1.01779e-07 2.5 0.671573 2.5 1.5Z"/>
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="12" height="12" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
name: "Handler"
}
</script>

<style scoped lang="scss">
@import "~@/assets/styles/color-scheme.scss";
.handler {
  margin: 0 8px 0 0;
  padding: 0;
  width: 12px;
  height: 12px;
  fill: $pxp-gray-color-darken;

  &:hover {
    fill: $pxp-blue-added-color;
    cursor: grab;
  }

  &:active {
    cursor: grabbing;
  }
}
</style>