<template>
  <div class="collaborators-view">
    <template v-if="email !== ''">
      <Avatar :src="require('@/assets/images/temp_avatar.png')" :size="24" class="avatar"/>
      <p>{{email}}</p>
    </template>
    <template v-else>
      <Avatar :id="'collaborator-left-' + fullName" :src="avatarThumbnail" :full-name="fullName" :size="24" class="avatar"/>
      <p>{{fullName}}</p>
    </template>
  </div>
</template>

<script>
import Avatar from "@/components/UI-Kit/Avatar";
import {Image} from "@/components/UI-Kit/Helper classes/image";
import {reactive, computed} from "vue";
export default {
  name: "CollaboratorsView",
  components: {
    Avatar
  },
  props: {
    user: {
      type: Object,
      default: {}
    },
    email: {
      type: String,
      default: ""
    }
  },
  setup (props) {
    if (props.email === "") {
      const user = reactive({
        firstName: props.user.firstName,
        lastName: props.user.lastName,
        avatar: props.user.avatar,
        fullName: computed(() => user.firstName + " " + user.lastName),
        shortFullName: computed(
            () => user.firstName + " " + user.lastName[0] + "."
        ),
        avatarSmall: computed(() => {
          if (user.avatar && user.avatar.formats && user.avatar.formats.small && user.avatar.formats.small.url) {
            return new Image(
                user.avatar.formats.small.url,
                user.firstName + "'s photo"
            );
          }
        }),
        avatarThumbnail: computed(() => {
          if (
              user.avatar &&
              user.avatar.formats &&
              user.avatar.formats.thumbnail &&
              user.avatar.formats.thumbnail.url
          ) {
            return user.avatar.formats.thumbnail.url
          }
        })
      });
      return {
        ...user
      }
    }
    else {
      return {}
    }
  }
}
</script>

<style scoped lang="scss">
.collaborators-view {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;
  justify-self: self-start;

  p {
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;

    color: #C0CCFF;
  }

  .avatar {
    flex-shrink: 1;
  }

}
</style>