<template>
  <div
    class="pxp-input"
    :class="['size-' + size, 'mode-' + mode, error ? 'error' : '']"
  >
    <template v-if="label">
      <label :for="id">{{ label }}</label>
    </template>

    <div class="uk-inline">
      <template v-if="iconName && iconPosition">
        <Icon
          :name="iconName"
          class="uk-form-icon"
          :class="[
            iconPosition === 'right' ? 'uk-form-icon-flip' : '',
            'size-' + size
          ]"
        />
      </template>

      <input
        :id="id"
        :name="name"
        :type="type"
        :placeholder="placeholder"
        class="uk-input"
        :class="['mode-' + mode, 'size-' + size]"
        :value="modelValue"
        :disabled="disabled"
        @input="$emit('update:modelValue', $event.target.value)"
        @focusout="checkValue()"
        @focusin="resetCheck()"
      />
    </div>

    <div class="help">
      <template v-if="error">
        <p>{{ msg }}</p>
      </template>
      <template v-else>
        <slot name="help" />
      </template>
    </div>
  </div>
</template>

<script>
import Icon from "@/components/UI-Kit/IconNew";
import { ref, watch } from "vue";

const tiMode = ["auth", "default"];
const tiIconPos = ["left", "right"];
const tiSize = ["l", "m", "s"];

export default {
  name: "TextInputNew",
  components: { Icon },
  props: {
    // v-model
    modelValue: {
      type: String
    },

    // label
    label: {
      type: [String, undefined],
      required: false,
      default: undefined
    },

    // input
    type: {
      type: String,
      default: "text"
    },
    id: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: false
    },
    placeholder: {
      type: String,
      required: false,
      default: ""
    },

    // icon
    iconName: {
      type: [String, undefined],
      required: false,
      default: undefined
    },
    iconPosition: {
      type: String,
      required: false,
      default: "left",
      validator: pos => {
        return tiIconPos.indexOf(pos) !== -1;
      }
    },

    // mode
    mode: {
      type: String,
      required: false,
      default: "default",
      validator: mode => {
        return tiMode.indexOf(mode) !== -1;
      }
    },
    size: {
      type: String,
      required: false,
      default: "s",
      validate: size => {
        return tiSize.indexOf(size) !== -1;
      }
    },

    // help
    validate: {
      type: Array,
      required: false,
      default: () => []
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const error = ref(false);
    const msg = ref("");

    const checkValue = async function() {
      if (props.modelValue.length > 0) {
        for (let v of props.validate) {
          let err = v(props.modelValue);
          if (err) {
            error.value = true;
            msg.value = err;
            break;
          }
        }
      }
    };

    const resetCheck = function() {
      error.value = false;
      msg.value = "";
    };

    return {
      checkValue,
      resetCheck,
      error,
      msg
    };
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/styles/color-scheme.scss";
@import "~@/assets/styles/hover-active-change.scss";

.uk-inline {
  height: 100%;
  width: 100%;
}

.pxp-input {
  text-align: left;
  width: 100%;

  .uk-input {
    font-family: "Work Sans";

    &,
    &:hover,
    &:active,
    &:focus {
      border: none;
      outline: none;
    }
  }

  .uk-form-icon {
    &.size-l,
    &.size-m,
    &.size-s {
      width: 16px;
      height: 16px;
      margin: auto 12px;
    }
  }

  // Mode setting

  &.mode-default {
    label {
      color: #333333;
      font-style: normal;
      font-weight: 500;
    }
    .uk-input {
      background-color: $pxp-white-color;
      color: #333333;
      border: 1px solid $pxp-gray-color-lighten;
      border-radius: 4px;
      &:focus {
        border: 1px solid $pxp-blue-accent-color-brighter;
      }
    }
  }

  &.mode-onboarding-list {
    label {
      height: 0px;
    }
    .uk-input {
      background-color: white;
      border: 1px solid #80869D;
      box-sizing: border-box;
      border-radius: 4px;
      &:focus {
      }
    }
  }

  &.mode-canvas-list {
    .uk-input {
      background-color: white;
      box-sizing: border-box;
      padding: 0!important;
      font-style: normal;
      font-weight: normal;
      font-size: 14px!important;
      line-height: 16px!important;
      height: 16px!important;
      color: #000000;

      &:focus {
      }
    }
    .help {
      margin: 0!important;
    }
  }

  // Size setting

  &.size-l {
    .uk-input {
      height: 40px;
      font-size: 20px;
      line-height: 24px;
    }
    .help {
      margin-top: 12px;
      font-size: 16px;
      line-height: 19px;
    }
  }

  &.size-m {
    label {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #333333;
    }
    .uk-input {
      height: 36px;
      font-size: 16px;
      line-height: 19px;
    }
    .help {
      margin-top: 11px;
      font-size: 14px;
      line-height: 17.5px;
    }
  }

  &.size-s {
    label {
      font-size: 14px;
      line-height: 16px;
    }
    .uk-input {
      font-size: 13px;
      line-height: 15px;
      height: 32px;
    }
    .help {
      margin-top: 5px;
      font-size: 12px;
      line-height: 15px;
    }
  }

  &.size-s,
  &.size-l,
  &.size-m {
    .uk-input {
      font-style: normal;
      font-weight: normal;
      padding: 8px 16px;
    }
  }
}

.pxp-input.error {
  &.mode-default {
    .uk-input {
      border: 1px solid $pxp-red-color;
    }
    .help {
      color: $pxp-red-color;
    }
  }
}
</style>
