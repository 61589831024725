<template>
  <div class="research-question-wrapper" :class="[researchQuestion.sync? 'sync': '']">


    <div class="full-rq">
      <h3 @click="openUpdateResearchQuestion = true">{{researchQuestion.name}}</h3>
      <Checker class="check" mode="rq" :id="'rq-full-' + researchQuestion.id" :check-list="checker"/>
    </div>

    <div class="tasks">

      <draggable
        :list="researchQuestion.rqList"
        group="tasks"
        :move="checkMove"
        >
        <template #header>
          <div class="rq-tasks">
            <h4>Tasks</h4>
            <div class="action">
              <template v-if="researchQuestion.move[researchQuestion.move.length - 1].column !== 'done' && researchQuestion.move[researchQuestion.move.length - 1].column !== 'deleted'">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="icon" @click="createNewTask">
                  <circle cx="8" cy="8" r="7" class="circle"/>
                  <path d="M9 4H7V7H4V9H7V12H9V9H12V7H9V4Z" class="plus"/>
                </svg>
              </template>
              <template v-else>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="icon">
                  <path d="M4.66663 6.60696V3.99996C4.66918 3.11669 5.02118 2.27032 5.64575 1.64575C6.27032 1.02118 7.11669 0.669176 7.99996 0.666626V0.666626C8.88323 0.669176 9.7296 1.02118 10.3542 1.64575C10.9787 2.27032 11.3307 3.11669 11.3333 3.99996V6.60696" stroke="#BFC4D6" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M8 15.3333C10.7614 15.3333 13 13.0947 13 10.3333C13 7.57183 10.7614 5.33325 8 5.33325C5.23858 5.33325 3 7.57183 3 10.3333C3 13.0947 5.23858 15.3333 8 15.3333Z" stroke="#BFC4D6" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M7.99996 10.9999C8.73634 10.9999 9.33329 10.403 9.33329 9.66659C9.33329 8.93021 8.73634 8.33325 7.99996 8.33325C7.26358 8.33325 6.66663 8.93021 6.66663 9.66659C6.66663 10.403 7.26358 10.9999 7.99996 10.9999Z" stroke="#BFC4D6" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M8 11V12.6667" stroke="#BFC4D6" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </template>
            </div>

          </div>
          <template v-if="!researchQuestion.rqList || researchQuestion.rqList.length === 0">
            <div class="empty-tasks">
              <p>You haven’t added any tasks yet.<br>
                What are you going to do to answer<br>
                this question?</p>
              <p>Click <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="icon" @click="createNewTask">
                <circle cx="8" cy="8" r="7" class="circle"/>
                <path d="M9 4H7V7H4V9H7V12H9V9H12V7H9V4Z" class="plus"/></svg></p>

            </div>
          </template>
        </template>
        <template #item="{element, index}">
          <TaskCard mode="rq" :task="element"/>
        </template>
      </draggable>
    </div>

    <UpdateResearchQuestion :research-question="researchQuestion" v-model:show="openUpdateResearchQuestion"/>
  </div>


</template>

<script>
import Checker from "@/components/UI-Kit/Checker";
import IconNew from "@/components/UI-Kit/IconNew";
import TaskCard from "@/components/Research Question/TaskCard";
import draggable from "vuedraggable";
import moment from "moment";
import {computed, ref, watch, reactive} from "vue"
import UpdateResearchQuestion from "@/components/Research Question/UpdateResearchQuestion";

export default {
  name: "ResearchQuestionCard",
  components: {UpdateResearchQuestion, TaskCard, Checker, IconNew, draggable},
  props: {
    researchQuestion: {
      type: [Object, undefined],
      default: undefined
    },
    mode: {
      type: String,
      default: 'sprint',
      validate: (mode) => ['sprint', 'todo'].findIndex(item => item === mode) !== -1
    }
  },
  setup (props, {emit}) {
    const createNewTask = function () {
      // create task
      const task = {
        name: "",
        researchQuestion: props.researchQuestion.id,
        move: [{
        }]
      }
      // edit move

      let endRqTask
      if (props.researchQuestion.rqList.length !== 0) {
        endRqTask = props.researchQuestion.rqList[props.researchQuestion.rqList.length - 1]
      } else {
        endRqTask = {
          type: 'rq',
          id: 'top'
        }
      }

      task.move[0].neighborTop = endRqTask.type + '-' + endRqTask.id
      task.move[0].neighborBottom = 'rq-bottom'
      task.move[0].date = (new Date()).toISOString()
      task.move[0].column = props.researchQuestion.move[props.researchQuestion.move.length - 1].column

      task.sync = true

      emit('new-task-added', task)
    }

    const openUpdateResearchQuestion = ref(false)

    const checker = reactive([
      computed(() => props.researchQuestion.name? props.researchQuestion.name.length > 0: false),
      computed(() => props.researchQuestion.details? props.researchQuestion.details.length > 0: false),
      computed(() => props.researchQuestion.learnings? props.researchQuestion.learnings.length > 0: false),
      computed(() => props.researchQuestion.plans? props.researchQuestion.plans.length > 0: false)
    ])

    const checkMove = function () {
      if (props.researchQuestion.move[props.researchQuestion.move.length - 1].column === 'done' || props.researchQuestion.move[props.researchQuestion.move.length - 1].column === 'deleted')
        return false
    }

    return {
      createNewTask,
      openUpdateResearchQuestion,
      checker,
      checkMove
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/styles/color-scheme.scss";

.research-question-wrapper {

  background: #FFFFFF;
  box-shadow: 0 4px 8px -4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 8px;
  box-sizing: border-box;

  &.without-rq {
    margin-top: -20px;
  }

  margin-bottom: 8px;

  &.sync {
    border: 3px solid $pxp-white-accent-color-brighter;
  }

  h3 {
    text-align: left;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    margin: 0 8px 8px 8px;
  }
}

.full-rq {
  h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: left;
    cursor: pointer;
  }
  .check {
    height: 8px;
    margin:  0 8px;
  }
}

.rq-tasks {
  display: flex;
  flex-direction: row;
  align-items: center;

  h4 {
    text-align: left;
    font-size: 12px!important;
    line-height: 14px;
    margin: 16px 8px 8px 8px;
  }

  .action {
    margin: 16px 8px 8px 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    .icon {
      cursor: pointer;
      &:hover {

        .circle {
          fill: #0CAC60;
        }
        .plus {
          fill: #E3E5EC;
        }
      }
      .circle {
        fill: #E3E5EC;
      }
      .plus {
        fill: #13246E;
      }
    }

  }

}

.empty-tasks {
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    margin: 24px 8px 24px 8px;
    /* or 21px */

    text-align: center;

    color: #80869D;
  }

  .icon {
    margin-top: -4px;
    margin-left: 4px;
    cursor: pointer;
    &:hover {

      .circle {
        fill: #0CAC60;
      }
      .plus {
        fill: #E3E5EC;
      }
    }
    .circle {
      fill: #E3E5EC;
    }
    .plus {
      fill: #13246E;
    }
  }

}
</style>