import axios from "axios";

export async function loginRequestApi(email, password) {
  const response = await axios.post("/auth/local", {
    identifier: email,
    password: password
  });

  return response.data;
}

export async function registrationRequestApi(email, password, userData) {
  const response = await axios.post("auth/local/register", {
    username: email,
    email,
    password,
    ...userData
  });

  return response.data;
}

export async function forgotPasswordRequestApi(email) {
  const response = await axios.post("auth/forgot-password", {
    email
  });

  return response.data;
}

export async function resetPasswordRequestApi(code, password) {
  const response = await axios.post("auth/reset-password", {
    code,
    password,
    passwordConfirmation: password
  });

  return response.data;
}

export async function userInfoRequestApi() {
  const response = await axios.get("users/me");

  return response.data;
}

export async function getWorkspacesRequestApi() {
  const response = await axios.get("workspaces/my");

  return response.data;
}


export async function updateUserInfoRequestApi(avatar, userData) {
  const data = new FormData();

  if (typeof avatar !== 'undefined') {
    data.append("files.avatar", avatar);
  }

  data.append(
      "data",
      JSON.stringify({
        firstName: userData.firstName,
        lastName: userData.lastName,
        university: userData.university,
        email: userData.email
      })
  );

  const response = await axios.put("users/me", data, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  })

  return response.data
}