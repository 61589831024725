<template>
  <div class="canvas">
    <h3 class="dashboard__title">Research Canvas</h3>
    <template v-if="loadCanvas">
      <CircleLoader class="load"/>
    </template>
    <template v-else>

      <div class="canvas-grid">
        <CanvasCard
            v-for="(cardName, index) in canvasCardName"
            :key="'canvas-card-name-id-' + cardName.id"
            :canvas-card-name="cardName"
            :canvas-card-data="canvasCardData[index]"
            :id="'q' + (index + 1)"
            :style="{ gridArea: 'q' + (index + 1) }"
            @updated="saveCard(index)"
            :workspace-id="workspaceId"
        >
        </CanvasCard>
      </div>

    </template>
  </div>
</template>

<script>
import CanvasCard from "@/components/PhD Canvas/CanvasCard";
import { getCanvasCardName, getAllCanvasData, saveCanvasData } from "@/api/canvas";
import {
  ref,
  toRefs,
  toRef,
  reactive, // (https://v3.vuejs.org/api/refs-api.html#ref)
  computed,
  watch, // (https://v3.vuejs.org/api/computed-watch-api.html)
  onMounted,
  onUpdated,
  onUnmounted,
  inject// (https://learnvue.co/2020/03/how-to-use-lifecycle-hooks-in-vue3/)
} from "vue";
import CircleLoader from "@/components/UI-Kit/loaders/CircleLoader";


const setup = function(props) {
  const socket = inject('socket')
  const user = inject('user')
  const loadCanvas = ref(false);
  const canvasCardName = ref(undefined);
  const canvasCardData = ref(undefined);


  socket.value.on('updated_canvas_data', (data) => {
    const authorId = data.author
    const canvasData = data.data
    if (authorId !== user.id) {
      canvasCardData.value[canvasData.question - 1].data = canvasData.data
    }
  })

  const getCanvasCardNameRequest = async function() {
    canvasCardName.value = await getCanvasCardName();
  };

  const getCanvasCardDataRequest = async function() {
    const canvasCardResponse = await getAllCanvasData(props.workspaceId)
    canvasCardData.value = canvasCardResponse.map((item) => reactive(item));
  };

  const initCanvas = async function () {
    loadCanvas.value = true
    await getCanvasCardNameRequest();
    await getCanvasCardDataRequest();
    loadCanvas.value = false
  }

  watch(() => props.workspaceId, (newValue) => {
    initCanvas()
  })

  initCanvas()

  const saveCard = function (index) {
    const card = canvasCardData.value[index]
    saveCanvasData(card.question, props.workspaceId,{ data: card.data })
  }

  return {
    canvasCardData,
    canvasCardName,
    saveCard,
    loadCanvas
  };
};

export default {
  name: "Canvas",
  components: {CircleLoader, CanvasCard },
  props: {
    workspaceId: {
      type: String,
      required: true
    }
  },
  setup
};
</script>

<style scoped lang="scss">
.canvas {
  .dashboard__title {
    padding: 0 8px;
  }
}

.canvas-grid {
  display: grid;
  box-sizing: border-box;
  grid-template-areas:
    "q4   q8   q10"
    "q2   q6   q5"
    "q9   q11  q3"
    "q7   q13  q12"
    "q1   q1   q1";
  grid-template-columns: 1fr 1fr 1fr;
  align-items: start;
}

.load {
  width: 100%;
}
</style>
