<template>
  <div class="todo-today">
    <template v-if="loading">
      <h3 class="dashboard__title">To Do Today</h3>
      <!-- Загрузка -->
      <CircleLoader/>
    </template>
    <template v-else>
      <template v-if="actualTTTasks.length === 0 || countPostpone === actualTTTasks.length">
        <h3 class="dashboard__title">To Do Today</h3>
        <!-- Приветственный баннер, предлагающий назначить задание -->
        <div class="set-task">
          <div class="left">
            <IconNew name="todo-preview" class="icon"/>
          </div>
          <div class="right">
            <h2>Hello {{user.firstName}}!</h2>
            <template  v-if="sortedRqData.todo.length === 0 && sortedRqData.doing.length === 0">
              <p class="set-task-message">
                Here you will find tasks from your current Sprint to keep track of your work and start your day off right.
                But now all you have to do is to create your first Sprint!
              </p>
              <ButtonNew mode="green" size="l" text="Create Sprint" class="button" @click="toSprint"/>
            </template>
            <template v-else>
              <ButtonNew mode="green" size="l" text="Choose tasks for today" class="button" @click="openManageTodoModal"/>
            </template>
          </div>
        </div>
      </template>
      <template v-else-if="actualTTTasks.length > 0">
        <h3 class="dashboard__title">To Do Today <ButtonNew
            mode="text"
            icon-name="edit-blue"
            text="Manage tasks"
            size="s"
            icon-position="left"
            @click="openManageTodoModal"
        /></h3>
        <!-- Отображение активных задач -->
        <div class="active-task">
          <template v-for="(todoTask, index) in actualTTTasks" :key="'actual-todo-task-' + index">
            <template v-if="!checkPostpone(index)">
              <ActualTask :workspace-id="workspaceId" :index="index" :todo-task="todoTask"/>
            </template>
          </template>
        </div>
      </template>
      <ManageTasksModal v-model:show="openManageTodoTasks" :workspace-id="workspaceId" :actual-todo-tasks="actualTodoTasks"/>
    </template>
  </div>
</template>

<script>
import Card from "@/components/UI-Kit/Card";
import ButtonNew from "@/components/UI-Kit/ButtonNew";
import Icon from "@/components/UI-Kit/Icon";
import IconNew from "@/components/UI-Kit/IconNew";
import {ref, reactive, computed, watch, inject} from "vue";
import CircleLoader from "@/components/UI-Kit/loaders/CircleLoader";
import {getActualTodoTask} from "@/api/todotoday";
import ManageTasksModal from "@/components/To do today/ManageTasksModal";
import ActualTask from "@/components/To do today/ActualTask";
import {useRouter} from "vue-router";

export default {
  name: "TodoToday",
  components: {ActualTask, CircleLoader, IconNew, Icon, ButtonNew, Card, ManageTasksModal},
  props: {
    workspaceId: {
      type: String
    }
  },
  setup (props) {
    const router = useRouter()
    const user = inject("user")
    const loadTTTaskData = inject("loadTTTaskData")
    const actualTTTasks = inject("actualTTTasks")
    const loadRQData = inject("loadRQStatus")
    const countPostpone = inject("countPostpone")
    const sortedRqData = inject("sortedRQData")
    const loading = computed(() => loadRQData.value === 'loading' && loadTTTaskData.value)

    // manage
    const openManageTodoTasks = ref(false)

    const openManageTodoModal = function () {
      openManageTodoTasks.value = true
    }

    const toSprint = function () {
      router.push({
        name: "Sprints",
        params: {
          workspaceId: props.workspaceId
        }
      })
    }

    const checkPostpone = inject("checkPostponeByIndex")

    return {
      user,
      actualTTTasks,
      openManageTodoTasks, openManageTodoModal,
      loading,
      checkPostpone,
      countPostpone,
      toSprint,
      sortedRqData
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/styles/color-scheme.scss";
.set-task {
  background-color: $pxp-white-color;
  box-shadow: 0 4px 8px -4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  padding: 16px;
  .left {
    .icon {
      margin-right: 24px;
      width: 105px;
      height: 89px;
    }
  }
  .right {
    text-align: left;

    h2 {
      margin-bottom: 8px;
    }

    .set-task-message {}

    .button {
      margin-top: 16px;
      width: 230px;
    }
  }
}

.todo-today {
  padding: 0 8px;
}
</style>