<template>
  <div :id="id" class="uk-flex-top" :class="classSettingWrapper" uk-modal>
    <div class="uk-modal-dialog" :class="classSetting">
      <button :class="classSettingButton" type="button" uk-close />

      <template v-if="header">
        <!-- Header -->
        <div class="uk-modal-header" :class="[paddingNotRemove? '': 'uk-padding-remove']">
          <slot name="header" />
        </div>
      </template>

      <!-- Body -->
      <div class="uk-modal-body" :class="[paddingNotRemove? '': 'uk-padding-remove']">
        <slot />
      </div>

      <template v-if="footer">
        <!-- Footer -->
        <div class="uk-modal-footer" :class="[paddingNotRemove? '': 'uk-padding-remove']">
          <slot name="footer" />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import UIkit from "uikit";
import { watch, onMounted } from "vue";

export default {
  name: "Modal",
  props: {
    id: {
      type: String,
      required: true
    },
    show: {
      type: Boolean,
      required: false
    },
    isOverflow: {
      type: Boolean,
      default: false
    },

    isFullscreen: {
      type: Boolean,
      default: false
    },

    isCenter: {
      type: Boolean,
      default: false
    },

    header: {
      type: Boolean,
      default: false
    },
    footer: {
      type: Boolean,
      default: false
    },
    paddingNotRemove: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  setup(props, { emit }) {
    const classSetting = {
      "uk-margin-auto-vertical": props.isCenter
    };

    const classSettingWrapper = {
      "uk-modal-full": props.isFullscreen
    };

    const classSettingButton = [
      props.isFullscreen
        ? "uk-modal-close-full uk-close-large"
        : "uk-modal-close-default"
    ];

    let thisModal;

    const displayModal = () => {
      if (props.show) thisModal.show();
      else thisModal.hide();
    };

    onMounted(() => {
      thisModal = UIkit.modal(document.querySelector(`#${props.id}`));

      UIkit.util.on(`#${props.id}`, "hidden", function() {
        emit("update:show", false);
      });

      displayModal();
    });

    watch(() => props.show, displayModal);

    return {
      classSetting,
      classSettingWrapper,
      classSettingButton
    };
  }
};
</script>

<style lang="scss">
.uk-modal-dialog {
  border-radius: 10px;
  box-sizing: border-box;
}

.uk-modal-header {
  border-radius: 8px 8px 0 0;
  background-color: white;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  /* identical to box height, or 150% */

  color: #000000;
}

.uk-modal-footer {
  border-radius: 0 0 8px 8px;

}

.uk-modal-full {
  .uk-modal-dialog {
    min-height: 100vh !important;
  }
}
</style>
