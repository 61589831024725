import '@stripe/stripe-js';
import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";


const app = createApp(App)

app.config.warnHandler = () => null;
app.use(router);
app.mount("#app");
