import axios from "axios";

export async function getSubscriptionInfo () {
    const response = await axios.get("users/me/subscription")

    return response.data;
}

export async function getSessionIdEditCard () {
    const response = await axios.get("/users/pay-sessions/edit-card")

    return response.data;
}

export async function setNewPlan(plan) {
    let response
    if (plan === 'basic')
        response = await axios.post("/users/set-plan/basic")
    else if (plan === 'pro')
        response = await axios.post("/users/set-plan/pro")
    else if (plan === 'member')
        response = await axios.post("/users/set-plan/member")

    return response.data
}