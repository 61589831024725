import axios from "axios";

export async function deleteCanvasData(question, workspace) {
  try {
    const response = await axios.delete("/canvas-data", {
      data: {
        workspace,
        question
      }
    });

    return response.data;
  } catch {
    throw new Error();
  }
}

export async function getAllCanvasData(workspace) {
  const response = await axios.get("/canvas-data", {
    params: {
      workspace
    }
  });

  let canvasData = response.data
  canvasData.sort((l, r) => l.question - r.question)

  return response.data;
} // Get canvas data object sorted by question

export async function saveCanvasData(question, workspace, data) {
  const response = await axios.post("/canvas-data", {
    workspace,
    data,
    question
  });

  return response.data;
}

export async function getCanvasCardName() {
  const response = await axios.get("/canvas-card-name");

  return response.data.canvasName;
}

export async function getOnboardingQuestions() {
  try {
    const response = await axios.get("/onboarding-question");

    return response.data.onboardingQuestions;
  } catch {
    throw new Error();
  }
}
