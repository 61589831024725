<template>
  <div class="workspace-card" :class="[isOwner? 'owner': '', isDashboard? 'dashboard': '']" @click="toWorkspace">
      <div class="image">
        <template v-if="workspace.workCover && workspace.workCover.formats && workspace.workCover.formats.thumbnail">
          <img :src="workspace.workCover.formats.thumbnail.url"/>
          <template v-if="isDashboard">
            <div class="edit-avatar" @click="openEdit">
              <IconNew name="edit" class="icon"/>
            </div>
          </template>
        </template>
        <template v-else>
          <img :src="require('@/assets/images/phd-cover.png')"/>
          <template v-if="isDashboard">
            <div class="edit-avatar" @click="openEdit">
              <IconNew name="edit" class="icon"/>
            </div>
          </template>
        </template>
      </div>
      <div class="description" :class="[isDashboard? 'dashboard': '']">
        <template v-if="isDashboard">
          <template v-if="editTitle || workspace.workName.length === 0">
            <input class="work-title"
                   id="title"
                   :value="workspace.workName"
                   @input="workspace.workName = $event.target.value"
                   @focusout="saveTitle"
                   placeholder="Add your PhD title"/>
          </template>
          <template v-else>
            <h1 class="light" @click="editTitle = true">{{ workspace.workName }}</h1>
          </template>
          <template v-if="editDescription || workspace.workDescription.length === 0">
            <input class="work-description"
                   id="description"
                   :value="workspace.workDescription"
                   @input="workspace.workDescription = $event.target.value"
                   @focusout="saveDescription"
                   placeholder="Add short description of your PhD"
            >
          </template>
          <template v-else>
            <p @click="editDescription = true">{{ workspace.workDescription }}</p>
          </template>
        </template>
        <template v-else>
          <h1 class="light">{{ workspace.workName }}</h1>
          <p>{{ workspace.workDescription }}</p>
        </template>

      </div>
    <Modal header footer padding-not-remove is-center id="edit-workspace-avatar-modal" v-model:show="showModalAvatarEdit">
      <template v-slot:header>
        <h1>Upload workspace image</h1>
      </template>
      <AvatarEditor
          v-model="newImage" id="edit-workspace-avatar" rounded
      />
      <template v-slot:footer>
        <ButtonNew mode="green" size="l" text="Save change" :loading="loadingUpdateImage" @click="updateImage"/>
      </template>
    </Modal>
  </div>
</template>

<script>
import Card from "@/components/UI-Kit/Card";
import { useRouter } from "vue-router";
import {ref, watch} from "vue";
import IconNew from "@/components/UI-Kit/IconNew";
import Modal from "@/components/UI-Kit/Modal";
import AvatarEditor from "@/components/UI-Kit/AvatarEditor";
import ButtonNew from "@/components/UI-Kit/ButtonNew";
import {updateWorkspace, updateWorkspaceAvatar} from "@/api/workspace";

export default {
  name: "WorkspaceCard",
  components: {AvatarEditor, Modal, IconNew, Card, ButtonNew },
  props: {
    workspace: {
      type: Object,
      required: false,
      default: () => ({
        name: "Hello",
        description:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
      })
    },
    isOwner: {
      type: Boolean,
      default: false
    },
    isDashboard: {
      type: Boolean,
      default: false
    }
  },
  setup(props, {emit}) {

    const router = useRouter();
    const newImage = ref({});
    const showModalAvatarEdit = ref(false);
    const loadingUpdateImage = ref(false);

    const toWorkspace = function() {
      router.push("/workspace/" + props.workspace.id);
    };

    const saveTitle = function () {
      editTitle.value = false
      emit('update-title', props.workspace.workName)
    }

    const saveDescription = function () {
      editDescription.value = false
      emit('update-description', props.workspace.workDescription)
    }

    const openEdit = function () {
      showModalAvatarEdit.value = true
    }

    const updateImage = async function () {
      loadingUpdateImage.value = true;
      await updateWorkspaceAvatar(props.workspace.id, newImage.value)
      loadingUpdateImage.value = false;
      showModalAvatarEdit.value = false;
    }

    const editTitle = ref(false)
    const editDescription = ref(false)

    return {
      toWorkspace,
      saveTitle, saveDescription,
      openEdit, showModalAvatarEdit, newImage,
      updateImage, loadingUpdateImage,
      editTitle, editDescription
    };
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/styles/color-scheme.scss";
.workspace-card {
  width: 100%;
  margin-bottom: 15px;
  box-sizing: border-box;
  padding: 15px;
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  background-color: $pxp-white-color;
  border-radius: 20px;
  border: 1px solid $pxp-blue-accent-color-dimmer;
  cursor: pointer;

  &.dashboard {
    background-color: rgba(0, 0, 0, 0);
    border: none;
    padding-left: 0;
    cursor: default!important;
    &:hover {
      box-shadow: none!important;
    }
  }

  &.owner {
    border: 1px dashed $pxp-blue-accent-color-dimmer;
    margin-top: 20px;
  }

  &:hover {
    box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.1);
  }

  .image {
    width: 52px;
    height: 52px;
    margin-right: 16px;
    flex-shrink: 0;
    img {
      border-radius: 8px;
    }
  }
}

.description.dashboard {
  width: 100%;
  input {
    display: block;
    width: 100%;
    border: none;
    background-color: $pxp-gray-added-color;
    cursor: pointer;
    &:active,
    &:focus {
      border: none;
      outline: none;
    }
    &:focus:hover {
      cursor: text;
    }
  }

}

.work-title {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 26px;
  color: $pxp-black-color;
  width: 100%;
  margin: 0;
}
.work-description {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  margin: 0;
  width: 100%;
  color: $pxp-gray-color-darken;
}

.edit-avatar {
  width: 18px;
  height: 18px;
  background-color: $pxp-blue-color-darken;
  position: relative;
  bottom: 18px;
  left: 34px;
  border-radius: 4px;
  opacity: 0.6;
  cursor: pointer;

  .icon {
    width: 12px;
    height: 12px;
    margin: 3px;
  }
}
</style>
