<template>
  <div class="loading-view">
    <div class="loading-view__content">
      <div class="loading-view__logo">
        <img src="@/assets/images/pxp-logo-white.svg" alt="pagexpage logo" />
      </div>
      <div class="loading-view__message">
        <CoveringMessage />
      </div>
      <div class="loading-view__loader">
        <CircleLoader :ratio="2" />
      </div>
    </div>
  </div>
</template>

<script>
import CoveringMessage from "@/components/CoveringMessage";
import CircleLoader from "@/components/UI-Kit/loaders/CircleLoader";
export default {
  name: "LoadingView",
  components: { CircleLoader, CoveringMessage }
};
</script>

<style scoped lang="scss">
@import "~@/assets/styles/variables.scss";

.loading-view {
  height: 100vh;
  width: 100vw;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  background-color: $global-primary-background;

  &__content {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: red;
    display: grid;
    justify-items: center;
    grid-template-areas:
      "lw__logo"
      "lw__message"
      "lw__loader";
  }

  &__logo {
    width: 182px;
    height: 31.95px;
    margin-top: 37px;
    grid-area: lw__logo;
    align-self: self-start;
  }

  &__message {
    text-align: center;
    grid-area: lw__message;
    align-self: center;
  }

  &__loader {
    grid-area: lw__loader;
    align-self: self-start;
    margin-top: 48px;
    color: white;
    font-size: 30px;
  }
}
</style>
