<template>
  <Modal padding-not-remove class="extended-modal" :id="'add-result-todo-task-' + todoTask.id" is-center header footer :show="show" >
    <template #header>
      <h1>Congratulations!</h1>
    </template>
    <div class="body">
      <template v-if="relateTask">
        <h2 class="add-result-task"><span class="relate-task">{{relateTask.name}}</span> is complete</h2>
      </template>
      <h3 class="add-result-label">Here’s what you planned:</h3>
      <TextInputNew size="l" :id="'todo-task-res-plan-' + todoTask.id" v-model="todoTask.plan" disabled/>
      <h3 class="add-result-label">What are the actual results?</h3>
      <TextArea
          :id="'todo-task-res-results-' + todoTask.id"
          placeholder="Actual results" v-model="actualResults"/>
      <h3 class="add-result-label">How many Pomodoro blocks did it actually take?</h3>
      <PomodoroTime :max="8" v-model="needPomodoro"/>
    </div>
    <template #footer>
      <div class="action">
        <ButtonNew text="Save" mode="green" size="l" class="save-button" @click="saveResults"/>
        <ButtonNew text="Go to Sprint" mode="text" size="s" icon-position="left" icon-name="link" @click="goToSprints"/>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/UI-Kit/Modal";
import ButtonNew from "@/components/UI-Kit/ButtonNew";
import TextInputNew from "@/components/UI-Kit/TextInputNew";
import TextArea from "@/components/UI-Kit/TextArea";

import {useRouter} from "vue-router"

import {ref, computed, watch, inject} from "vue"
import PomodoroTime from "@/components/UI-Kit/PomodoroTime";
export default {
  name: "AddResultTaskModal",
  components: {PomodoroTime, TextArea, TextInputNew, ButtonNew, Modal},
  props: {
    show: Boolean,
    todoTask: Object,
    workspaceId: String,
    index: Number
  },
  setup (props, {emit}) {
    const actualResults = ref(props.todoTask.result)
    const needPomodoro = ref(props.todoTask.needPomodoro)

    const router = useRouter()

    const tasks = inject("tasks")
    const rqs = inject("rqs")

    const updateTTTaskByIndex = inject("updateTTTaskByIndex")

    const relateTask = computed(() => {
      if (!props.todoTask.hasOwnProperty('type') && props.todoTask.task && tasks[props.todoTask.task]) {
        let task = tasks[props.todoTask.task]
        return {
          name: task.name,
          rq: typeof task.researchQuestion === "object"? task.researchQuestion.name: rqs[task.researchQuestion].name
        }
      } else {
        return undefined
      }
    })

    const goToSprints = function() {
      router.push({
        name: "Sprints",
        params: {
          workspaceId: props.workspaceId
        }
      })
    }

    const saveResults = function() {
      props.todoTask.result = actualResults.value
      props.todoTask.needPomodoro = needPomodoro.value
      updateTTTaskByIndex(props.index)
      emit("update:show", false)
    }

    watch(() => props.show, (newValue) => {
      emit("update:show", newValue)
    })

    return {
      actualResults,
      needPomodoro,
      saveResults,
      relateTask,
      goToSprints
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/styles/utility.scss";

.body {
  @include suppress-scroll;

  margin-top: -20px;
  padding: 4px;
  .add-result-task {
    margin-bottom: 16px;
    .relate-task {
      font-style: italic;
    }
  }

  .add-result-label {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 8px;

    color: #000000;

    opacity: 0.8;
  }
}

.action {
  display: flex;
  flex-direction: row;
  align-items: center;

  .save-button {
    width: 127px;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height, or 137% */

    display: flex;
    align-items: center;
    text-align: center;
    margin-right: 12px;
  }
}
</style>