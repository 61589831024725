<template>
  <p
    :id="id"
    :placeholder="placeholder"
    class="text-editor-block"
    :class="{ empty: isEmpty && !isActive, active: isActive }"
    contenteditable
    @click.once="setActiveByClick"
    @focus="setActive"
    @focusout="setUnactive"
    @keypress.enter="pressEnter"
  >
    {{ isActive ? item : item || placeholder }}
  </p>
</template>

<script>
import { ref, computed, onMounted, watch } from "vue";

function setEndOfContenteditable(contentEditableElement)
{
  var range,selection;
  if(document.createRange)//Firefox, Chrome, Opera, Safari, IE 9+
  {
    range = document.createRange();//Create a range (a range is a like the selection but invisible)
    range.selectNodeContents(contentEditableElement);//Select the entire contents of the element with the range
    range.collapse(false);//collapse the range to the end point. false means collapse to end rather than the start
    selection = window.getSelection();//get the selection object (allows you to change selection)
    selection.removeAllRanges();//remove any selections already made
    selection.addRange(range);//make the range you have just created the visible selection
  }
  else if(document.selection)//IE 8 and lower
  {
    range = document.body.createTextRange();//Create a range (a range is a like the selection but invisible)
    range.moveToElementText(contentEditableElement);//Select the entire contents of the element with the range
    range.collapse(false);//collapse the range to the end point. false means collapse to end rather than the start
    range.select();//Select the range (make it the visible selection
  }
}


export default {
  name: "TextEditorBlock",
  props: {
    id: {
      type: String
    },
    item: {
      type: String
    },
    placeholder: {
      type: String,
      default: ""
    },
    targ: {
      type: String,
      default: "text"
    }
  },
  setup: function(props, { emit }) {
    const isActive = ref(false);
    const isClick = ref(false);
    const myIndex = computed(() => {
      const index = props.id.split("-");
      return Number.parseInt(index[index.length - 1]);
    });
    const isEmpty = computed(() => props.item.length === 0);

    let editor = undefined;

    const setActive = function() {
      if (props.targ === "text" && editor) {
        setEndOfContenteditable(editor)
      }
      isActive.value = true;
    };

    const setActiveByClick = function() {
      console.log("SetActivityByClick");
      isClick.value = true;
      isActive.value = true;
    };

    const setUnactive = function() {
      console.log("setUnactive");
      if (isActive.value) {
        updateItem();
        deleteEmptyItem();
        isActive.value = false;
        isClick.value = false;
      }
    };

    onMounted(() => {
      editor = document.querySelector(`#${props.id}`);
    });

    const updateItem = function() {
      console.log("updateItem");
      const newValue = editor.textContent || "";
      emit("update:item", newValue === props.placeholder ? "" : newValue);
      emit("updated")
    };

    const deleteEmptyItem = function() {
      if (editor.textContent.length === 0) {
        emit("delete-item", myIndex);
        editor.blur();
      }
    };

    const pressEnter = function (event) {
      if (props.targ === 'inline') {
        event.preventDefault();
      }
    }

    return {
      isActive,
      isEmpty,
      myIndex,
      setActiveByClick,
      setActive,
      setUnactive,
      pressEnter
    };
  }
};
</script>

<style scoped lang="scss">
.text-editor-block {
  width: 100%;
  margin: 0;
  padding: 0;
  user-select: text;

  &:active,
  &:focus {
    outline: none;
    text-decoration: none;
  }

  word-wrap: break-word;
}
</style>
