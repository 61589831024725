<template>
  <div class="pomodoro-time">
    <template v-for="number in max">
      <div class="pomodoro" :class="[number <= input? 'active': '']" @click="input = number">
        <template v-if="number <= input">
          <IconNew name="timer-green" class="pomodoro-item"/>
        </template>
        <template v-else>
          <IconNew name="timer" class="pomodoro-item"/>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import {ref, watch} from "vue";
import IconNew from "@/components/UI-Kit/IconNew";

export default {
  name: "PomodoroTime",
  components: {IconNew},
  props: {
    modelValue: Number,
    max: Number
  },
  setup (props, {emit}) {
    const input = ref(props.modelValue)

    watch(input, (newValue) => {
      emit("update:modelValue", newValue)
      emit("change")
    })

    return {
      input
    }
  }

}
</script>

<style scoped lang="scss">
.pomodoro-time {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}
.pomodoro {
  width: 40px;
  height: 40px;
  .pomodoro-item {
    width: 40px;
    height: 40px;
  }
  cursor: pointer;
}
</style>